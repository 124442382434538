/* eslint-disable */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var toit_model_data_pb = require('../../toit/model/data_pb.js');
goog.object.extend(proto, toit_model_data_pb);
goog.exportSymbol('proto.toit.model.ConnectionSetting', null, global);
goog.exportSymbol('proto.toit.model.DeviceAction', null, global);
goog.exportSymbol('proto.toit.model.DeviceBootStatus', null, global);
goog.exportSymbol('proto.toit.model.DeviceBrokerSettings', null, global);
goog.exportSymbol('proto.toit.model.DeviceChangeType', null, global);
goog.exportSymbol('proto.toit.model.DeviceConfig', null, global);
goog.exportSymbol('proto.toit.model.DeviceConnectionSettings', null, global);
goog.exportSymbol('proto.toit.model.DeviceConnectionStatus', null, global);
goog.exportSymbol('proto.toit.model.DeviceConnectionType', null, global);
goog.exportSymbol('proto.toit.model.DeviceEvent', null, global);
goog.exportSymbol('proto.toit.model.DeviceEvent.Info', null, global);
goog.exportSymbol('proto.toit.model.DeviceEvent.Info.Value', null, global);
goog.exportSymbol('proto.toit.model.DeviceEvent.Info.Value.Diff', null, global);
goog.exportSymbol('proto.toit.model.DeviceEvent.Info.Value.Error', null, global);
goog.exportSymbol('proto.toit.model.DeviceEvent.Info.Value.Primitive', null, global);
goog.exportSymbol('proto.toit.model.DeviceEvent.Info.Value.Primitive.Type', null, global);
goog.exportSymbol('proto.toit.model.DeviceEvent.Initiater', null, global);
goog.exportSymbol('proto.toit.model.DeviceEvent.Initiater.Console', null, global);
goog.exportSymbol('proto.toit.model.DeviceEvent.Initiater.Device', null, global);
goog.exportSymbol('proto.toit.model.DeviceEvent.Initiater.User', null, global);
goog.exportSymbol('proto.toit.model.DeviceEvent.Type', null, global);
goog.exportSymbol('proto.toit.model.DeviceHealth', null, global);
goog.exportSymbol('proto.toit.model.DeviceHealth.BatteryCurve', null, global);
goog.exportSymbol('proto.toit.model.DeviceHealth.Connectivity', null, global);
goog.exportSymbol('proto.toit.model.DeviceHealth.Connectivity.Checkin', null, global);
goog.exportSymbol('proto.toit.model.DeviceHealth.Power', null, global);
goog.exportSymbol('proto.toit.model.DeviceHealth.Power.Value', null, global);
goog.exportSymbol('proto.toit.model.DeviceModelSettings', null, global);
goog.exportSymbol('proto.toit.model.DeviceModule', null, global);
goog.exportSymbol('proto.toit.model.DeviceModuleType', null, global);
goog.exportSymbol('proto.toit.model.DeviceModules', null, global);
goog.exportSymbol('proto.toit.model.DeviceModulesConfig', null, global);
goog.exportSymbol('proto.toit.model.DeviceModulesStatus', null, global);
goog.exportSymbol('proto.toit.model.DeviceRuntimeSettings', null, global);
goog.exportSymbol('proto.toit.model.DeviceSessionChangeType', null, global);
goog.exportSymbol('proto.toit.model.DeviceStatus', null, global);
goog.exportSymbol('proto.toit.model.HardwareIdentity', null, global);
goog.exportSymbol('proto.toit.model.HardwareIdentityInfo', null, global);
goog.exportSymbol('proto.toit.model.HardwareInfo', null, global);
goog.exportSymbol('proto.toit.model.HardwareToDeviceInfo', null, global);
goog.exportSymbol('proto.toit.model.LoggingSettings', null, global);
goog.exportSymbol('proto.toit.model.MetricsSettings', null, global);
goog.exportSymbol('proto.toit.model.NBIoTSetting', null, global);
goog.exportSymbol('proto.toit.model.WifiSetting', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceConfig.displayName = 'proto.toit.model.DeviceConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceModulesConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceModulesConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceModulesConfig.displayName = 'proto.toit.model.DeviceModulesConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceRuntimeSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceRuntimeSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceRuntimeSettings.displayName = 'proto.toit.model.DeviceRuntimeSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.LoggingSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.LoggingSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.LoggingSettings.displayName = 'proto.toit.model.LoggingSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.MetricsSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.MetricsSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.MetricsSettings.displayName = 'proto.toit.model.MetricsSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceConnectionSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.toit.model.DeviceConnectionSettings.repeatedFields_, null);
};
goog.inherits(proto.toit.model.DeviceConnectionSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceConnectionSettings.displayName = 'proto.toit.model.DeviceConnectionSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.ConnectionSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.ConnectionSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.ConnectionSetting.displayName = 'proto.toit.model.ConnectionSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.WifiSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.WifiSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.WifiSetting.displayName = 'proto.toit.model.WifiSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.NBIoTSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.toit.model.NBIoTSetting.repeatedFields_, null);
};
goog.inherits(proto.toit.model.NBIoTSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.NBIoTSetting.displayName = 'proto.toit.model.NBIoTSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceBrokerSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceBrokerSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceBrokerSettings.displayName = 'proto.toit.model.DeviceBrokerSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceStatus.displayName = 'proto.toit.model.DeviceStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceModulesStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceModulesStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceModulesStatus.displayName = 'proto.toit.model.DeviceModulesStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceModules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceModules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceModules.displayName = 'proto.toit.model.DeviceModules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceModule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceModule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceModule.displayName = 'proto.toit.model.DeviceModule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceConnectionStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceConnectionStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceConnectionStatus.displayName = 'proto.toit.model.DeviceConnectionStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceBootStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceBootStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceBootStatus.displayName = 'proto.toit.model.DeviceBootStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceHealth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceHealth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceHealth.displayName = 'proto.toit.model.DeviceHealth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceHealth.Power = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceHealth.Power, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceHealth.Power.displayName = 'proto.toit.model.DeviceHealth.Power';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceHealth.Power.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.toit.model.DeviceHealth.Power.Value.oneofGroups_);
};
goog.inherits(proto.toit.model.DeviceHealth.Power.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceHealth.Power.Value.displayName = 'proto.toit.model.DeviceHealth.Power.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceHealth.BatteryCurve = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceHealth.BatteryCurve, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceHealth.BatteryCurve.displayName = 'proto.toit.model.DeviceHealth.BatteryCurve';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceHealth.Connectivity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.toit.model.DeviceHealth.Connectivity.repeatedFields_, null);
};
goog.inherits(proto.toit.model.DeviceHealth.Connectivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceHealth.Connectivity.displayName = 'proto.toit.model.DeviceHealth.Connectivity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceHealth.Connectivity.Checkin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceHealth.Connectivity.Checkin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceHealth.Connectivity.Checkin.displayName = 'proto.toit.model.DeviceHealth.Connectivity.Checkin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.HardwareIdentity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.HardwareIdentity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.HardwareIdentity.displayName = 'proto.toit.model.HardwareIdentity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.HardwareIdentityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.HardwareIdentityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.HardwareIdentityInfo.displayName = 'proto.toit.model.HardwareIdentityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.HardwareInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.HardwareInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.HardwareInfo.displayName = 'proto.toit.model.HardwareInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.HardwareToDeviceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.HardwareToDeviceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.HardwareToDeviceInfo.displayName = 'proto.toit.model.HardwareToDeviceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.toit.model.DeviceEvent.repeatedFields_, null);
};
goog.inherits(proto.toit.model.DeviceEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceEvent.displayName = 'proto.toit.model.DeviceEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceEvent.Initiater = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.toit.model.DeviceEvent.Initiater.oneofGroups_);
};
goog.inherits(proto.toit.model.DeviceEvent.Initiater, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceEvent.Initiater.displayName = 'proto.toit.model.DeviceEvent.Initiater';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceEvent.Initiater.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceEvent.Initiater.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceEvent.Initiater.Device.displayName = 'proto.toit.model.DeviceEvent.Initiater.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceEvent.Initiater.Console = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceEvent.Initiater.Console, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceEvent.Initiater.Console.displayName = 'proto.toit.model.DeviceEvent.Initiater.Console';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceEvent.Initiater.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceEvent.Initiater.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceEvent.Initiater.User.displayName = 'proto.toit.model.DeviceEvent.Initiater.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceEvent.Info = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceEvent.Info, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceEvent.Info.displayName = 'proto.toit.model.DeviceEvent.Info';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceEvent.Info.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.toit.model.DeviceEvent.Info.Value.oneofGroups_);
};
goog.inherits(proto.toit.model.DeviceEvent.Info.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceEvent.Info.Value.displayName = 'proto.toit.model.DeviceEvent.Info.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceEvent.Info.Value.Primitive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceEvent.Info.Value.Primitive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceEvent.Info.Value.Primitive.displayName = 'proto.toit.model.DeviceEvent.Info.Value.Primitive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceEvent.Info.Value.Diff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceEvent.Info.Value.Diff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceEvent.Info.Value.Diff.displayName = 'proto.toit.model.DeviceEvent.Info.Value.Diff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceEvent.Info.Value.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceEvent.Info.Value.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceEvent.Info.Value.Error.displayName = 'proto.toit.model.DeviceEvent.Info.Value.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.DeviceModelSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.DeviceModelSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.DeviceModelSettings.displayName = 'proto.toit.model.DeviceModelSettings';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startedAfter: (f = msg.getStartedAfter()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sdk: jspb.Message.getFieldWithDefault(msg, 3, ""),
    broker: (f = msg.getBroker()) && proto.toit.model.DeviceBrokerSettings.toObject(includeInstance, f),
    connection: (f = msg.getConnection()) && proto.toit.model.DeviceConnectionSettings.toObject(includeInstance, f),
    model: jspb.Message.getFieldWithDefault(msg, 6, ""),
    factoryAfter: (f = msg.getFactoryAfter()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    runtime: (f = msg.getRuntime()) && proto.toit.model.DeviceRuntimeSettings.toObject(includeInstance, f),
    modules: (f = msg.getModules()) && proto.toit.model.DeviceModulesConfig.toObject(includeInstance, f),
    modelSettings: (f = msg.getModelSettings()) && proto.toit.model.DeviceModelSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceConfig}
 */
proto.toit.model.DeviceConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceConfig;
  return proto.toit.model.DeviceConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceConfig}
 */
proto.toit.model.DeviceConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartedAfter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdk(value);
      break;
    case 4:
      var value = new proto.toit.model.DeviceBrokerSettings;
      reader.readMessage(value,proto.toit.model.DeviceBrokerSettings.deserializeBinaryFromReader);
      msg.setBroker(value);
      break;
    case 5:
      var value = new proto.toit.model.DeviceConnectionSettings;
      reader.readMessage(value,proto.toit.model.DeviceConnectionSettings.deserializeBinaryFromReader);
      msg.setConnection(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFactoryAfter(value);
      break;
    case 8:
      var value = new proto.toit.model.DeviceRuntimeSettings;
      reader.readMessage(value,proto.toit.model.DeviceRuntimeSettings.deserializeBinaryFromReader);
      msg.setRuntime(value);
      break;
    case 9:
      var value = new proto.toit.model.DeviceModulesConfig;
      reader.readMessage(value,proto.toit.model.DeviceModulesConfig.deserializeBinaryFromReader);
      msg.setModules(value);
      break;
    case 10:
      var value = new proto.toit.model.DeviceModelSettings;
      reader.readMessage(value,proto.toit.model.DeviceModelSettings.deserializeBinaryFromReader);
      msg.setModelSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartedAfter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSdk();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBroker();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.toit.model.DeviceBrokerSettings.serializeBinaryToWriter
    );
  }
  f = message.getConnection();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.toit.model.DeviceConnectionSettings.serializeBinaryToWriter
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFactoryAfter();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRuntime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.toit.model.DeviceRuntimeSettings.serializeBinaryToWriter
    );
  }
  f = message.getModules();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.toit.model.DeviceModulesConfig.serializeBinaryToWriter
    );
  }
  f = message.getModelSettings();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.toit.model.DeviceModelSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.toit.model.DeviceConfig.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.model.DeviceConfig.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp started_after = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.DeviceConfig.prototype.getStartedAfter = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.DeviceConfig.prototype.setStartedAfter = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceConfig.prototype.clearStartedAfter = function() {
  this.setStartedAfter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceConfig.prototype.hasStartedAfter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string sdk = 3;
 * @return {string}
 */
proto.toit.model.DeviceConfig.prototype.getSdk = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.toit.model.DeviceConfig.prototype.setSdk = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DeviceBrokerSettings broker = 4;
 * @return {?proto.toit.model.DeviceBrokerSettings}
 */
proto.toit.model.DeviceConfig.prototype.getBroker = function() {
  return /** @type{?proto.toit.model.DeviceBrokerSettings} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceBrokerSettings, 4));
};


/** @param {?proto.toit.model.DeviceBrokerSettings|undefined} value */
proto.toit.model.DeviceConfig.prototype.setBroker = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceConfig.prototype.clearBroker = function() {
  this.setBroker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceConfig.prototype.hasBroker = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DeviceConnectionSettings connection = 5;
 * @return {?proto.toit.model.DeviceConnectionSettings}
 */
proto.toit.model.DeviceConfig.prototype.getConnection = function() {
  return /** @type{?proto.toit.model.DeviceConnectionSettings} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceConnectionSettings, 5));
};


/** @param {?proto.toit.model.DeviceConnectionSettings|undefined} value */
proto.toit.model.DeviceConfig.prototype.setConnection = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceConfig.prototype.clearConnection = function() {
  this.setConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceConfig.prototype.hasConnection = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string model = 6;
 * @return {string}
 */
proto.toit.model.DeviceConfig.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.toit.model.DeviceConfig.prototype.setModel = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp factory_after = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.DeviceConfig.prototype.getFactoryAfter = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.DeviceConfig.prototype.setFactoryAfter = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceConfig.prototype.clearFactoryAfter = function() {
  this.setFactoryAfter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceConfig.prototype.hasFactoryAfter = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DeviceRuntimeSettings runtime = 8;
 * @return {?proto.toit.model.DeviceRuntimeSettings}
 */
proto.toit.model.DeviceConfig.prototype.getRuntime = function() {
  return /** @type{?proto.toit.model.DeviceRuntimeSettings} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceRuntimeSettings, 8));
};


/** @param {?proto.toit.model.DeviceRuntimeSettings|undefined} value */
proto.toit.model.DeviceConfig.prototype.setRuntime = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceConfig.prototype.clearRuntime = function() {
  this.setRuntime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceConfig.prototype.hasRuntime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional DeviceModulesConfig modules = 9;
 * @return {?proto.toit.model.DeviceModulesConfig}
 */
proto.toit.model.DeviceConfig.prototype.getModules = function() {
  return /** @type{?proto.toit.model.DeviceModulesConfig} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceModulesConfig, 9));
};


/** @param {?proto.toit.model.DeviceModulesConfig|undefined} value */
proto.toit.model.DeviceConfig.prototype.setModules = function(value) {
  jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceConfig.prototype.clearModules = function() {
  this.setModules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceConfig.prototype.hasModules = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional DeviceModelSettings model_settings = 10;
 * @return {?proto.toit.model.DeviceModelSettings}
 */
proto.toit.model.DeviceConfig.prototype.getModelSettings = function() {
  return /** @type{?proto.toit.model.DeviceModelSettings} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceModelSettings, 10));
};


/** @param {?proto.toit.model.DeviceModelSettings|undefined} value */
proto.toit.model.DeviceConfig.prototype.setModelSettings = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceConfig.prototype.clearModelSettings = function() {
  this.setModelSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceConfig.prototype.hasModelSettings = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceModulesConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceModulesConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceModulesConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceModulesConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    syncRequested: (f = msg.getSyncRequested()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modules: (f = msg.getModules()) && proto.toit.model.DeviceModules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceModulesConfig}
 */
proto.toit.model.DeviceModulesConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceModulesConfig;
  return proto.toit.model.DeviceModulesConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceModulesConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceModulesConfig}
 */
proto.toit.model.DeviceModulesConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSyncRequested(value);
      break;
    case 2:
      var value = new proto.toit.model.DeviceModules;
      reader.readMessage(value,proto.toit.model.DeviceModules.deserializeBinaryFromReader);
      msg.setModules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceModulesConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceModulesConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceModulesConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceModulesConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSyncRequested();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.toit.model.DeviceModules.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp sync_requested = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.DeviceModulesConfig.prototype.getSyncRequested = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.DeviceModulesConfig.prototype.setSyncRequested = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceModulesConfig.prototype.clearSyncRequested = function() {
  this.setSyncRequested(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceModulesConfig.prototype.hasSyncRequested = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeviceModules modules = 2;
 * @return {?proto.toit.model.DeviceModules}
 */
proto.toit.model.DeviceModulesConfig.prototype.getModules = function() {
  return /** @type{?proto.toit.model.DeviceModules} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceModules, 2));
};


/** @param {?proto.toit.model.DeviceModules|undefined} value */
proto.toit.model.DeviceModulesConfig.prototype.setModules = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceModulesConfig.prototype.clearModules = function() {
  this.setModules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceModulesConfig.prototype.hasModules = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceRuntimeSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceRuntimeSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceRuntimeSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceRuntimeSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    logging: (f = msg.getLogging()) && proto.toit.model.LoggingSettings.toObject(includeInstance, f),
    metrics: (f = msg.getMetrics()) && proto.toit.model.MetricsSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceRuntimeSettings}
 */
proto.toit.model.DeviceRuntimeSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceRuntimeSettings;
  return proto.toit.model.DeviceRuntimeSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceRuntimeSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceRuntimeSettings}
 */
proto.toit.model.DeviceRuntimeSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.toit.model.LoggingSettings;
      reader.readMessage(value,proto.toit.model.LoggingSettings.deserializeBinaryFromReader);
      msg.setLogging(value);
      break;
    case 2:
      var value = new proto.toit.model.MetricsSettings;
      reader.readMessage(value,proto.toit.model.MetricsSettings.deserializeBinaryFromReader);
      msg.setMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceRuntimeSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceRuntimeSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceRuntimeSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceRuntimeSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogging();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.toit.model.LoggingSettings.serializeBinaryToWriter
    );
  }
  f = message.getMetrics();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.toit.model.MetricsSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional LoggingSettings logging = 1;
 * @return {?proto.toit.model.LoggingSettings}
 */
proto.toit.model.DeviceRuntimeSettings.prototype.getLogging = function() {
  return /** @type{?proto.toit.model.LoggingSettings} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.LoggingSettings, 1));
};


/** @param {?proto.toit.model.LoggingSettings|undefined} value */
proto.toit.model.DeviceRuntimeSettings.prototype.setLogging = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceRuntimeSettings.prototype.clearLogging = function() {
  this.setLogging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceRuntimeSettings.prototype.hasLogging = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MetricsSettings metrics = 2;
 * @return {?proto.toit.model.MetricsSettings}
 */
proto.toit.model.DeviceRuntimeSettings.prototype.getMetrics = function() {
  return /** @type{?proto.toit.model.MetricsSettings} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.MetricsSettings, 2));
};


/** @param {?proto.toit.model.MetricsSettings|undefined} value */
proto.toit.model.DeviceRuntimeSettings.prototype.setMetrics = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceRuntimeSettings.prototype.clearMetrics = function() {
  this.setMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceRuntimeSettings.prototype.hasMetrics = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.LoggingSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.LoggingSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.LoggingSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.LoggingSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    level: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.LoggingSettings}
 */
proto.toit.model.LoggingSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.LoggingSettings;
  return proto.toit.model.LoggingSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.LoggingSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.LoggingSettings}
 */
proto.toit.model.LoggingSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.toit.model.LogData.Level} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.LoggingSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.LoggingSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.LoggingSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.LoggingSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional LogData.Level level = 1;
 * @return {!proto.toit.model.LogData.Level}
 */
proto.toit.model.LoggingSettings.prototype.getLevel = function() {
  return /** @type {!proto.toit.model.LogData.Level} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.toit.model.LogData.Level} value */
proto.toit.model.LoggingSettings.prototype.setLevel = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.MetricsSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.MetricsSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.MetricsSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.MetricsSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    level: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.MetricsSettings}
 */
proto.toit.model.MetricsSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.MetricsSettings;
  return proto.toit.model.MetricsSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.MetricsSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.MetricsSettings}
 */
proto.toit.model.MetricsSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.toit.model.MetricsData.Level} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.MetricsSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.MetricsSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.MetricsSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.MetricsSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional MetricsData.Level level = 1;
 * @return {!proto.toit.model.MetricsData.Level}
 */
proto.toit.model.MetricsSettings.prototype.getLevel = function() {
  return /** @type {!proto.toit.model.MetricsData.Level} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.toit.model.MetricsData.Level} value */
proto.toit.model.MetricsSettings.prototype.setLevel = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.toit.model.DeviceConnectionSettings.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceConnectionSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceConnectionSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceConnectionSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceConnectionSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxOffline: (f = msg.getMaxOffline()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    connectionsList: jspb.Message.toObjectList(msg.getConnectionsList(),
    proto.toit.model.ConnectionSetting.toObject, includeInstance),
    eventQueueThreshold: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceConnectionSettings}
 */
proto.toit.model.DeviceConnectionSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceConnectionSettings;
  return proto.toit.model.DeviceConnectionSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceConnectionSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceConnectionSettings}
 */
proto.toit.model.DeviceConnectionSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setMaxOffline(value);
      break;
    case 2:
      var value = new proto.toit.model.ConnectionSetting;
      reader.readMessage(value,proto.toit.model.ConnectionSetting.deserializeBinaryFromReader);
      msg.addConnections(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventQueueThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceConnectionSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceConnectionSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceConnectionSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceConnectionSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxOffline();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getConnectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.toit.model.ConnectionSetting.serializeBinaryToWriter
    );
  }
  f = message.getEventQueueThreshold();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.Duration max_offline = 1;
 * @return {?proto.google.protobuf.Duration}
 */
proto.toit.model.DeviceConnectionSettings.prototype.getMaxOffline = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 1));
};


/** @param {?proto.google.protobuf.Duration|undefined} value */
proto.toit.model.DeviceConnectionSettings.prototype.setMaxOffline = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceConnectionSettings.prototype.clearMaxOffline = function() {
  this.setMaxOffline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceConnectionSettings.prototype.hasMaxOffline = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ConnectionSetting connections = 2;
 * @return {!Array<!proto.toit.model.ConnectionSetting>}
 */
proto.toit.model.DeviceConnectionSettings.prototype.getConnectionsList = function() {
  return /** @type{!Array<!proto.toit.model.ConnectionSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.toit.model.ConnectionSetting, 2));
};


/** @param {!Array<!proto.toit.model.ConnectionSetting>} value */
proto.toit.model.DeviceConnectionSettings.prototype.setConnectionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.toit.model.ConnectionSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.toit.model.ConnectionSetting}
 */
proto.toit.model.DeviceConnectionSettings.prototype.addConnections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.toit.model.ConnectionSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.toit.model.DeviceConnectionSettings.prototype.clearConnectionsList = function() {
  this.setConnectionsList([]);
};


/**
 * optional uint32 event_queue_threshold = 3;
 * @return {number}
 */
proto.toit.model.DeviceConnectionSettings.prototype.getEventQueueThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.toit.model.DeviceConnectionSettings.prototype.setEventQueueThreshold = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.ConnectionSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.ConnectionSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.ConnectionSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.ConnectionSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    wifi: (f = msg.getWifi()) && proto.toit.model.WifiSetting.toObject(includeInstance, f),
    nbiot: (f = msg.getNbiot()) && proto.toit.model.NBIoTSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.ConnectionSetting}
 */
proto.toit.model.ConnectionSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.ConnectionSetting;
  return proto.toit.model.ConnectionSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.ConnectionSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.ConnectionSetting}
 */
proto.toit.model.ConnectionSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.toit.model.WifiSetting;
      reader.readMessage(value,proto.toit.model.WifiSetting.deserializeBinaryFromReader);
      msg.setWifi(value);
      break;
    case 2:
      var value = new proto.toit.model.NBIoTSetting;
      reader.readMessage(value,proto.toit.model.NBIoTSetting.deserializeBinaryFromReader);
      msg.setNbiot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.ConnectionSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.ConnectionSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.ConnectionSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.ConnectionSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWifi();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.toit.model.WifiSetting.serializeBinaryToWriter
    );
  }
  f = message.getNbiot();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.toit.model.NBIoTSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional WifiSetting wifi = 1;
 * @return {?proto.toit.model.WifiSetting}
 */
proto.toit.model.ConnectionSetting.prototype.getWifi = function() {
  return /** @type{?proto.toit.model.WifiSetting} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.WifiSetting, 1));
};


/** @param {?proto.toit.model.WifiSetting|undefined} value */
proto.toit.model.ConnectionSetting.prototype.setWifi = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.ConnectionSetting.prototype.clearWifi = function() {
  this.setWifi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.ConnectionSetting.prototype.hasWifi = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NBIoTSetting nbiot = 2;
 * @return {?proto.toit.model.NBIoTSetting}
 */
proto.toit.model.ConnectionSetting.prototype.getNbiot = function() {
  return /** @type{?proto.toit.model.NBIoTSetting} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.NBIoTSetting, 2));
};


/** @param {?proto.toit.model.NBIoTSetting|undefined} value */
proto.toit.model.ConnectionSetting.prototype.setNbiot = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.ConnectionSetting.prototype.clearNbiot = function() {
  this.setNbiot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.ConnectionSetting.prototype.hasNbiot = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.WifiSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.WifiSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.WifiSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.WifiSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.WifiSetting}
 */
proto.toit.model.WifiSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.WifiSetting;
  return proto.toit.model.WifiSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.WifiSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.WifiSetting}
 */
proto.toit.model.WifiSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.WifiSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.WifiSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.WifiSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.WifiSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ssid = 1;
 * @return {string}
 */
proto.toit.model.WifiSetting.prototype.getSsid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.model.WifiSetting.prototype.setSsid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.toit.model.WifiSetting.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.toit.model.WifiSetting.prototype.setPassword = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.toit.model.NBIoTSetting.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.NBIoTSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.NBIoTSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.NBIoTSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.NBIoTSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    apn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bandsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    operator: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.NBIoTSetting}
 */
proto.toit.model.NBIoTSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.NBIoTSetting;
  return proto.toit.model.NBIoTSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.NBIoTSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.NBIoTSetting}
 */
proto.toit.model.NBIoTSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApn(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setBandsList(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperator(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.NBIoTSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.NBIoTSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.NBIoTSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.NBIoTSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBandsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getOperator();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string apn = 1;
 * @return {string}
 */
proto.toit.model.NBIoTSetting.prototype.getApn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.model.NBIoTSetting.prototype.setApn = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 bands = 2;
 * @return {!Array<number>}
 */
proto.toit.model.NBIoTSetting.prototype.getBandsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.toit.model.NBIoTSetting.prototype.setBandsList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.toit.model.NBIoTSetting.prototype.addBands = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.toit.model.NBIoTSetting.prototype.clearBandsList = function() {
  this.setBandsList([]);
};


/**
 * optional string operator = 3;
 * @return {string}
 */
proto.toit.model.NBIoTSetting.prototype.getOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.toit.model.NBIoTSetting.prototype.setOperator = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string pin = 4;
 * @return {string}
 */
proto.toit.model.NBIoTSetting.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.toit.model.NBIoTSetting.prototype.setPin = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceBrokerSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceBrokerSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceBrokerSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceBrokerSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    host: jspb.Message.getFieldWithDefault(msg, 1, ""),
    port: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    protocol: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceBrokerSettings}
 */
proto.toit.model.DeviceBrokerSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceBrokerSettings;
  return proto.toit.model.DeviceBrokerSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceBrokerSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceBrokerSettings}
 */
proto.toit.model.DeviceBrokerSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPort(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtocol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceBrokerSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceBrokerSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceBrokerSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceBrokerSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProtocol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string host = 1;
 * @return {string}
 */
proto.toit.model.DeviceBrokerSettings.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.model.DeviceBrokerSettings.prototype.setHost = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 port = 2;
 * @return {number}
 */
proto.toit.model.DeviceBrokerSettings.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.toit.model.DeviceBrokerSettings.prototype.setPort = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string cn = 3;
 * @return {string}
 */
proto.toit.model.DeviceBrokerSettings.prototype.getCn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.toit.model.DeviceBrokerSettings.prototype.setCn = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string protocol = 4;
 * @return {string}
 */
proto.toit.model.DeviceBrokerSettings.prototype.getProtocol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.toit.model.DeviceBrokerSettings.prototype.setProtocol = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    revision: jspb.Message.getFieldWithDefault(msg, 1, 0),
    epoch: msg.getEpoch_asB64(),
    stateRevision: jspb.Message.getFieldWithDefault(msg, 5, 0),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    configRevision: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sdk: jspb.Message.getFieldWithDefault(msg, 4, ""),
    model: jspb.Message.getFieldWithDefault(msg, 7, ""),
    config: (f = msg.getConfig()) && proto.toit.model.DeviceConfig.toObject(includeInstance, f),
    connected: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    boot: (f = msg.getBoot()) && proto.toit.model.DeviceBootStatus.toObject(includeInstance, f),
    health: (f = msg.getHealth()) && proto.toit.model.DeviceHealth.toObject(includeInstance, f),
    connection: (f = msg.getConnection()) && proto.toit.model.DeviceConnectionStatus.toObject(includeInstance, f),
    modules: (f = msg.getModules()) && proto.toit.model.DeviceModulesStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceStatus}
 */
proto.toit.model.DeviceStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceStatus;
  return proto.toit.model.DeviceStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceStatus}
 */
proto.toit.model.DeviceStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRevision(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEpoch(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStateRevision(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConfigRevision(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdk(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 8:
      var value = new proto.toit.model.DeviceConfig;
      reader.readMessage(value,proto.toit.model.DeviceConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnected(value);
      break;
    case 10:
      var value = new proto.toit.model.DeviceBootStatus;
      reader.readMessage(value,proto.toit.model.DeviceBootStatus.deserializeBinaryFromReader);
      msg.setBoot(value);
      break;
    case 11:
      var value = new proto.toit.model.DeviceHealth;
      reader.readMessage(value,proto.toit.model.DeviceHealth.deserializeBinaryFromReader);
      msg.setHealth(value);
      break;
    case 12:
      var value = new proto.toit.model.DeviceConnectionStatus;
      reader.readMessage(value,proto.toit.model.DeviceConnectionStatus.deserializeBinaryFromReader);
      msg.setConnection(value);
      break;
    case 13:
      var value = new proto.toit.model.DeviceModulesStatus;
      reader.readMessage(value,proto.toit.model.DeviceModulesStatus.deserializeBinaryFromReader);
      msg.setModules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRevision();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEpoch_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getStateRevision();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConfigRevision();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSdk();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.toit.model.DeviceConfig.serializeBinaryToWriter
    );
  }
  f = message.getConnected();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getBoot();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.toit.model.DeviceBootStatus.serializeBinaryToWriter
    );
  }
  f = message.getHealth();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.toit.model.DeviceHealth.serializeBinaryToWriter
    );
  }
  f = message.getConnection();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.toit.model.DeviceConnectionStatus.serializeBinaryToWriter
    );
  }
  f = message.getModules();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.toit.model.DeviceModulesStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 revision = 1;
 * @return {number}
 */
proto.toit.model.DeviceStatus.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.toit.model.DeviceStatus.prototype.setRevision = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes epoch = 6;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.DeviceStatus.prototype.getEpoch = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes epoch = 6;
 * This is a type-conversion wrapper around `getEpoch()`
 * @return {string}
 */
proto.toit.model.DeviceStatus.prototype.getEpoch_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEpoch()));
};


/**
 * optional bytes epoch = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEpoch()`
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceStatus.prototype.getEpoch_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEpoch()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.DeviceStatus.prototype.setEpoch = function(value) {
  jspb.Message.setProto3BytesField(this, 6, value);
};


/**
 * optional int64 state_revision = 5;
 * @return {number}
 */
proto.toit.model.DeviceStatus.prototype.getStateRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.toit.model.DeviceStatus.prototype.setStateRevision = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp updated = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.DeviceStatus.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.DeviceStatus.prototype.setUpdated = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceStatus.prototype.clearUpdated = function() {
  this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceStatus.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 config_revision = 3;
 * @return {number}
 */
proto.toit.model.DeviceStatus.prototype.getConfigRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.toit.model.DeviceStatus.prototype.setConfigRevision = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sdk = 4;
 * @return {string}
 */
proto.toit.model.DeviceStatus.prototype.getSdk = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.toit.model.DeviceStatus.prototype.setSdk = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string model = 7;
 * @return {string}
 */
proto.toit.model.DeviceStatus.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.toit.model.DeviceStatus.prototype.setModel = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional DeviceConfig config = 8;
 * @return {?proto.toit.model.DeviceConfig}
 */
proto.toit.model.DeviceStatus.prototype.getConfig = function() {
  return /** @type{?proto.toit.model.DeviceConfig} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceConfig, 8));
};


/** @param {?proto.toit.model.DeviceConfig|undefined} value */
proto.toit.model.DeviceStatus.prototype.setConfig = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceStatus.prototype.clearConfig = function() {
  this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceStatus.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool connected = 9;
 * @return {boolean}
 */
proto.toit.model.DeviceStatus.prototype.getConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.toit.model.DeviceStatus.prototype.setConnected = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional DeviceBootStatus boot = 10;
 * @return {?proto.toit.model.DeviceBootStatus}
 */
proto.toit.model.DeviceStatus.prototype.getBoot = function() {
  return /** @type{?proto.toit.model.DeviceBootStatus} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceBootStatus, 10));
};


/** @param {?proto.toit.model.DeviceBootStatus|undefined} value */
proto.toit.model.DeviceStatus.prototype.setBoot = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceStatus.prototype.clearBoot = function() {
  this.setBoot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceStatus.prototype.hasBoot = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DeviceHealth health = 11;
 * @return {?proto.toit.model.DeviceHealth}
 */
proto.toit.model.DeviceStatus.prototype.getHealth = function() {
  return /** @type{?proto.toit.model.DeviceHealth} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceHealth, 11));
};


/** @param {?proto.toit.model.DeviceHealth|undefined} value */
proto.toit.model.DeviceStatus.prototype.setHealth = function(value) {
  jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceStatus.prototype.clearHealth = function() {
  this.setHealth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceStatus.prototype.hasHealth = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional DeviceConnectionStatus connection = 12;
 * @return {?proto.toit.model.DeviceConnectionStatus}
 */
proto.toit.model.DeviceStatus.prototype.getConnection = function() {
  return /** @type{?proto.toit.model.DeviceConnectionStatus} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceConnectionStatus, 12));
};


/** @param {?proto.toit.model.DeviceConnectionStatus|undefined} value */
proto.toit.model.DeviceStatus.prototype.setConnection = function(value) {
  jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceStatus.prototype.clearConnection = function() {
  this.setConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceStatus.prototype.hasConnection = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional DeviceModulesStatus modules = 13;
 * @return {?proto.toit.model.DeviceModulesStatus}
 */
proto.toit.model.DeviceStatus.prototype.getModules = function() {
  return /** @type{?proto.toit.model.DeviceModulesStatus} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceModulesStatus, 13));
};


/** @param {?proto.toit.model.DeviceModulesStatus|undefined} value */
proto.toit.model.DeviceStatus.prototype.setModules = function(value) {
  jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceStatus.prototype.clearModules = function() {
  this.setModules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceStatus.prototype.hasModules = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceModulesStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceModulesStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceModulesStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceModulesStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastUpdated: (f = msg.getLastUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modules: (f = msg.getModules()) && proto.toit.model.DeviceModules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceModulesStatus}
 */
proto.toit.model.DeviceModulesStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceModulesStatus;
  return proto.toit.model.DeviceModulesStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceModulesStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceModulesStatus}
 */
proto.toit.model.DeviceModulesStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdated(value);
      break;
    case 2:
      var value = new proto.toit.model.DeviceModules;
      reader.readMessage(value,proto.toit.model.DeviceModules.deserializeBinaryFromReader);
      msg.setModules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceModulesStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceModulesStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceModulesStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceModulesStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastUpdated();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.toit.model.DeviceModules.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp last_updated = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.DeviceModulesStatus.prototype.getLastUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.DeviceModulesStatus.prototype.setLastUpdated = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceModulesStatus.prototype.clearLastUpdated = function() {
  this.setLastUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceModulesStatus.prototype.hasLastUpdated = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeviceModules modules = 2;
 * @return {?proto.toit.model.DeviceModules}
 */
proto.toit.model.DeviceModulesStatus.prototype.getModules = function() {
  return /** @type{?proto.toit.model.DeviceModules} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceModules, 2));
};


/** @param {?proto.toit.model.DeviceModules|undefined} value */
proto.toit.model.DeviceModulesStatus.prototype.setModules = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceModulesStatus.prototype.clearModules = function() {
  this.setModules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceModulesStatus.prototype.hasModules = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceModules.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceModules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceModules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceModules.toObject = function(includeInstance, msg) {
  var f, obj = {
    cellular: (f = msg.getCellular()) && proto.toit.model.DeviceModule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceModules}
 */
proto.toit.model.DeviceModules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceModules;
  return proto.toit.model.DeviceModules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceModules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceModules}
 */
proto.toit.model.DeviceModules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.toit.model.DeviceModule;
      reader.readMessage(value,proto.toit.model.DeviceModule.deserializeBinaryFromReader);
      msg.setCellular(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceModules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceModules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceModules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceModules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCellular();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.toit.model.DeviceModule.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeviceModule cellular = 1;
 * @return {?proto.toit.model.DeviceModule}
 */
proto.toit.model.DeviceModules.prototype.getCellular = function() {
  return /** @type{?proto.toit.model.DeviceModule} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceModule, 1));
};


/** @param {?proto.toit.model.DeviceModule|undefined} value */
proto.toit.model.DeviceModules.prototype.setCellular = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceModules.prototype.clearCellular = function() {
  this.setCellular(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceModules.prototype.hasCellular = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceModule.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceModule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceModule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceModule.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceModule}
 */
proto.toit.model.DeviceModule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceModule;
  return proto.toit.model.DeviceModule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceModule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceModule}
 */
proto.toit.model.DeviceModule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceModule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceModule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceModule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceModule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string model = 1;
 * @return {string}
 */
proto.toit.model.DeviceModule.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.model.DeviceModule.prototype.setModel = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.toit.model.DeviceModule.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.toit.model.DeviceModule.prototype.setVersion = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceConnectionStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceConnectionStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceConnectionStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceConnectionStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceConnectionStatus}
 */
proto.toit.model.DeviceConnectionStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceConnectionStatus;
  return proto.toit.model.DeviceConnectionStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceConnectionStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceConnectionStatus}
 */
proto.toit.model.DeviceConnectionStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {!proto.toit.model.DeviceConnectionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceConnectionStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceConnectionStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceConnectionStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceConnectionStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 index = 1;
 * @return {number}
 */
proto.toit.model.DeviceConnectionStatus.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.toit.model.DeviceConnectionStatus.prototype.setIndex = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DeviceConnectionType type = 2;
 * @return {!proto.toit.model.DeviceConnectionType}
 */
proto.toit.model.DeviceConnectionStatus.prototype.getType = function() {
  return /** @type {!proto.toit.model.DeviceConnectionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.toit.model.DeviceConnectionType} value */
proto.toit.model.DeviceConnectionStatus.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceBootStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceBootStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceBootStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceBootStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastBoot: (f = msg.getLastBoot()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    inFactory: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    lastFactoryBoot: (f = msg.getLastFactoryBoot()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceBootStatus}
 */
proto.toit.model.DeviceBootStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceBootStatus;
  return proto.toit.model.DeviceBootStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceBootStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceBootStatus}
 */
proto.toit.model.DeviceBootStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastBoot(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInFactory(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastFactoryBoot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceBootStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceBootStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceBootStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceBootStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastBoot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInFactory();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getLastFactoryBoot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp last_boot = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.DeviceBootStatus.prototype.getLastBoot = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.DeviceBootStatus.prototype.setLastBoot = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceBootStatus.prototype.clearLastBoot = function() {
  this.setLastBoot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceBootStatus.prototype.hasLastBoot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool in_factory = 2;
 * @return {boolean}
 */
proto.toit.model.DeviceBootStatus.prototype.getInFactory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.toit.model.DeviceBootStatus.prototype.setInFactory = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp last_factory_boot = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.DeviceBootStatus.prototype.getLastFactoryBoot = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.DeviceBootStatus.prototype.setLastFactoryBoot = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceBootStatus.prototype.clearLastFactoryBoot = function() {
  this.setLastFactoryBoot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceBootStatus.prototype.hasLastFactoryBoot = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceHealth.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceHealth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceHealth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceHealth.toObject = function(includeInstance, msg) {
  var f, obj = {
    power: (f = msg.getPower()) && proto.toit.model.DeviceHealth.Power.toObject(includeInstance, f),
    batteryCurve: (f = msg.getBatteryCurve()) && proto.toit.model.DeviceHealth.BatteryCurve.toObject(includeInstance, f),
    connectivity: (f = msg.getConnectivity()) && proto.toit.model.DeviceHealth.Connectivity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceHealth}
 */
proto.toit.model.DeviceHealth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceHealth;
  return proto.toit.model.DeviceHealth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceHealth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceHealth}
 */
proto.toit.model.DeviceHealth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.toit.model.DeviceHealth.Power;
      reader.readMessage(value,proto.toit.model.DeviceHealth.Power.deserializeBinaryFromReader);
      msg.setPower(value);
      break;
    case 2:
      var value = new proto.toit.model.DeviceHealth.BatteryCurve;
      reader.readMessage(value,proto.toit.model.DeviceHealth.BatteryCurve.deserializeBinaryFromReader);
      msg.setBatteryCurve(value);
      break;
    case 3:
      var value = new proto.toit.model.DeviceHealth.Connectivity;
      reader.readMessage(value,proto.toit.model.DeviceHealth.Connectivity.deserializeBinaryFromReader);
      msg.setConnectivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceHealth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceHealth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceHealth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceHealth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPower();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.toit.model.DeviceHealth.Power.serializeBinaryToWriter
    );
  }
  f = message.getBatteryCurve();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.toit.model.DeviceHealth.BatteryCurve.serializeBinaryToWriter
    );
  }
  f = message.getConnectivity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.toit.model.DeviceHealth.Connectivity.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceHealth.Power.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceHealth.Power.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceHealth.Power} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceHealth.Power.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && proto.toit.model.DeviceHealth.Power.Value.toObject(includeInstance, f),
    lastUpdated: (f = msg.getLastUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceHealth.Power}
 */
proto.toit.model.DeviceHealth.Power.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceHealth.Power;
  return proto.toit.model.DeviceHealth.Power.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceHealth.Power} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceHealth.Power}
 */
proto.toit.model.DeviceHealth.Power.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.toit.model.DeviceHealth.Power.Value;
      reader.readMessage(value,proto.toit.model.DeviceHealth.Power.Value.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceHealth.Power.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceHealth.Power.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceHealth.Power} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceHealth.Power.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.toit.model.DeviceHealth.Power.Value.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.toit.model.DeviceHealth.Power.Value.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.toit.model.DeviceHealth.Power.Value.PowerCase = {
  POWER_NOT_SET: 0,
  BATTERY: 1,
  PLUGGEDIN: 2
};

/**
 * @return {proto.toit.model.DeviceHealth.Power.Value.PowerCase}
 */
proto.toit.model.DeviceHealth.Power.Value.prototype.getPowerCase = function() {
  return /** @type {proto.toit.model.DeviceHealth.Power.Value.PowerCase} */(jspb.Message.computeOneofCase(this, proto.toit.model.DeviceHealth.Power.Value.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceHealth.Power.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceHealth.Power.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceHealth.Power.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceHealth.Power.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    battery: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    pluggedin: (f = msg.getPluggedin()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceHealth.Power.Value}
 */
proto.toit.model.DeviceHealth.Power.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceHealth.Power.Value;
  return proto.toit.model.DeviceHealth.Power.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceHealth.Power.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceHealth.Power.Value}
 */
proto.toit.model.DeviceHealth.Power.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBattery(value);
      break;
    case 2:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setPluggedin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceHealth.Power.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceHealth.Power.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceHealth.Power.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceHealth.Power.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getPluggedin();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional double battery = 1;
 * @return {number}
 */
proto.toit.model.DeviceHealth.Power.Value.prototype.getBattery = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.toit.model.DeviceHealth.Power.Value.prototype.setBattery = function(value) {
  jspb.Message.setOneofField(this, 1, proto.toit.model.DeviceHealth.Power.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.toit.model.DeviceHealth.Power.Value.prototype.clearBattery = function() {
  jspb.Message.setOneofField(this, 1, proto.toit.model.DeviceHealth.Power.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceHealth.Power.Value.prototype.hasBattery = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Empty pluggedin = 2;
 * @return {?proto.google.protobuf.Empty}
 */
proto.toit.model.DeviceHealth.Power.Value.prototype.getPluggedin = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 2));
};


/** @param {?proto.google.protobuf.Empty|undefined} value */
proto.toit.model.DeviceHealth.Power.Value.prototype.setPluggedin = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.toit.model.DeviceHealth.Power.Value.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceHealth.Power.Value.prototype.clearPluggedin = function() {
  this.setPluggedin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceHealth.Power.Value.prototype.hasPluggedin = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Value value = 1;
 * @return {?proto.toit.model.DeviceHealth.Power.Value}
 */
proto.toit.model.DeviceHealth.Power.prototype.getValue = function() {
  return /** @type{?proto.toit.model.DeviceHealth.Power.Value} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceHealth.Power.Value, 1));
};


/** @param {?proto.toit.model.DeviceHealth.Power.Value|undefined} value */
proto.toit.model.DeviceHealth.Power.prototype.setValue = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceHealth.Power.prototype.clearValue = function() {
  this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceHealth.Power.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.DeviceHealth.Power.prototype.getLastUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.DeviceHealth.Power.prototype.setLastUpdated = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceHealth.Power.prototype.clearLastUpdated = function() {
  this.setLastUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceHealth.Power.prototype.hasLastUpdated = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceHealth.BatteryCurve.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceHealth.BatteryCurve.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceHealth.BatteryCurve} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceHealth.BatteryCurve.toObject = function(includeInstance, msg) {
  var f, obj = {
    slope: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lastUpdated: (f = msg.getLastUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceHealth.BatteryCurve}
 */
proto.toit.model.DeviceHealth.BatteryCurve.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceHealth.BatteryCurve;
  return proto.toit.model.DeviceHealth.BatteryCurve.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceHealth.BatteryCurve} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceHealth.BatteryCurve}
 */
proto.toit.model.DeviceHealth.BatteryCurve.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSlope(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceHealth.BatteryCurve.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceHealth.BatteryCurve.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceHealth.BatteryCurve} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceHealth.BatteryCurve.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlope();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLastUpdated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional double slope = 1;
 * @return {number}
 */
proto.toit.model.DeviceHealth.BatteryCurve.prototype.getSlope = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.toit.model.DeviceHealth.BatteryCurve.prototype.setSlope = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp last_updated = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.DeviceHealth.BatteryCurve.prototype.getLastUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.DeviceHealth.BatteryCurve.prototype.setLastUpdated = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceHealth.BatteryCurve.prototype.clearLastUpdated = function() {
  this.setLastUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceHealth.BatteryCurve.prototype.hasLastUpdated = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.toit.model.DeviceHealth.Connectivity.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceHealth.Connectivity.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceHealth.Connectivity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceHealth.Connectivity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceHealth.Connectivity.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastSeen: (f = msg.getLastSeen()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    connected: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    checkinsList: jspb.Message.toObjectList(msg.getCheckinsList(),
    proto.toit.model.DeviceHealth.Connectivity.Checkin.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceHealth.Connectivity}
 */
proto.toit.model.DeviceHealth.Connectivity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceHealth.Connectivity;
  return proto.toit.model.DeviceHealth.Connectivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceHealth.Connectivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceHealth.Connectivity}
 */
proto.toit.model.DeviceHealth.Connectivity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSeen(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnected(value);
      break;
    case 3:
      var value = new proto.toit.model.DeviceHealth.Connectivity.Checkin;
      reader.readMessage(value,proto.toit.model.DeviceHealth.Connectivity.Checkin.deserializeBinaryFromReader);
      msg.addCheckins(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceHealth.Connectivity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceHealth.Connectivity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceHealth.Connectivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceHealth.Connectivity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastSeen();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConnected();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCheckinsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.toit.model.DeviceHealth.Connectivity.Checkin.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceHealth.Connectivity.Checkin.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceHealth.Connectivity.Checkin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceHealth.Connectivity.Checkin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceHealth.Connectivity.Checkin.toObject = function(includeInstance, msg) {
  var f, obj = {
    missed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceHealth.Connectivity.Checkin}
 */
proto.toit.model.DeviceHealth.Connectivity.Checkin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceHealth.Connectivity.Checkin;
  return proto.toit.model.DeviceHealth.Connectivity.Checkin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceHealth.Connectivity.Checkin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceHealth.Connectivity.Checkin}
 */
proto.toit.model.DeviceHealth.Connectivity.Checkin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMissed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceHealth.Connectivity.Checkin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceHealth.Connectivity.Checkin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceHealth.Connectivity.Checkin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceHealth.Connectivity.Checkin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMissed();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool missed = 1;
 * @return {boolean}
 */
proto.toit.model.DeviceHealth.Connectivity.Checkin.prototype.getMissed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.toit.model.DeviceHealth.Connectivity.Checkin.prototype.setMissed = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp last_seen = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.DeviceHealth.Connectivity.prototype.getLastSeen = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.DeviceHealth.Connectivity.prototype.setLastSeen = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceHealth.Connectivity.prototype.clearLastSeen = function() {
  this.setLastSeen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceHealth.Connectivity.prototype.hasLastSeen = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool connected = 2;
 * @return {boolean}
 */
proto.toit.model.DeviceHealth.Connectivity.prototype.getConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.toit.model.DeviceHealth.Connectivity.prototype.setConnected = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated Checkin checkins = 3;
 * @return {!Array<!proto.toit.model.DeviceHealth.Connectivity.Checkin>}
 */
proto.toit.model.DeviceHealth.Connectivity.prototype.getCheckinsList = function() {
  return /** @type{!Array<!proto.toit.model.DeviceHealth.Connectivity.Checkin>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.toit.model.DeviceHealth.Connectivity.Checkin, 3));
};


/** @param {!Array<!proto.toit.model.DeviceHealth.Connectivity.Checkin>} value */
proto.toit.model.DeviceHealth.Connectivity.prototype.setCheckinsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.toit.model.DeviceHealth.Connectivity.Checkin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.toit.model.DeviceHealth.Connectivity.Checkin}
 */
proto.toit.model.DeviceHealth.Connectivity.prototype.addCheckins = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.toit.model.DeviceHealth.Connectivity.Checkin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.toit.model.DeviceHealth.Connectivity.prototype.clearCheckinsList = function() {
  this.setCheckinsList([]);
};


/**
 * optional Power power = 1;
 * @return {?proto.toit.model.DeviceHealth.Power}
 */
proto.toit.model.DeviceHealth.prototype.getPower = function() {
  return /** @type{?proto.toit.model.DeviceHealth.Power} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceHealth.Power, 1));
};


/** @param {?proto.toit.model.DeviceHealth.Power|undefined} value */
proto.toit.model.DeviceHealth.prototype.setPower = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceHealth.prototype.clearPower = function() {
  this.setPower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceHealth.prototype.hasPower = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BatteryCurve battery_curve = 2;
 * @return {?proto.toit.model.DeviceHealth.BatteryCurve}
 */
proto.toit.model.DeviceHealth.prototype.getBatteryCurve = function() {
  return /** @type{?proto.toit.model.DeviceHealth.BatteryCurve} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceHealth.BatteryCurve, 2));
};


/** @param {?proto.toit.model.DeviceHealth.BatteryCurve|undefined} value */
proto.toit.model.DeviceHealth.prototype.setBatteryCurve = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceHealth.prototype.clearBatteryCurve = function() {
  this.setBatteryCurve(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceHealth.prototype.hasBatteryCurve = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Connectivity connectivity = 3;
 * @return {?proto.toit.model.DeviceHealth.Connectivity}
 */
proto.toit.model.DeviceHealth.prototype.getConnectivity = function() {
  return /** @type{?proto.toit.model.DeviceHealth.Connectivity} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceHealth.Connectivity, 3));
};


/** @param {?proto.toit.model.DeviceHealth.Connectivity|undefined} value */
proto.toit.model.DeviceHealth.prototype.setConnectivity = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceHealth.prototype.clearConnectivity = function() {
  this.setConnectivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceHealth.prototype.hasConnectivity = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.HardwareIdentity.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.HardwareIdentity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.HardwareIdentity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.HardwareIdentity.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareId: msg.getHardwareId_asB64(),
    sequenceNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, ""),
    privateKey: msg.getPrivateKey_asB64(),
    certificate: msg.getCertificate_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.HardwareIdentity}
 */
proto.toit.model.HardwareIdentity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.HardwareIdentity;
  return proto.toit.model.HardwareIdentity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.HardwareIdentity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.HardwareIdentity}
 */
proto.toit.model.HardwareIdentity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHardwareId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSequenceNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatch(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPrivateKey(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCertificate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.HardwareIdentity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.HardwareIdentity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.HardwareIdentity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.HardwareIdentity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getSequenceNumber();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrivateKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getCertificate_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional bytes hardware_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.HardwareIdentity.prototype.getHardwareId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes hardware_id = 1;
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {string}
 */
proto.toit.model.HardwareIdentity.prototype.getHardwareId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHardwareId()));
};


/**
 * optional bytes hardware_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {!Uint8Array}
 */
proto.toit.model.HardwareIdentity.prototype.getHardwareId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHardwareId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.HardwareIdentity.prototype.setHardwareId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional int64 sequence_number = 2;
 * @return {number}
 */
proto.toit.model.HardwareIdentity.prototype.getSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.toit.model.HardwareIdentity.prototype.setSequenceNumber = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string batch = 3;
 * @return {string}
 */
proto.toit.model.HardwareIdentity.prototype.getBatch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.toit.model.HardwareIdentity.prototype.setBatch = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bytes private_key = 4;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.HardwareIdentity.prototype.getPrivateKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes private_key = 4;
 * This is a type-conversion wrapper around `getPrivateKey()`
 * @return {string}
 */
proto.toit.model.HardwareIdentity.prototype.getPrivateKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPrivateKey()));
};


/**
 * optional bytes private_key = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPrivateKey()`
 * @return {!Uint8Array}
 */
proto.toit.model.HardwareIdentity.prototype.getPrivateKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPrivateKey()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.HardwareIdentity.prototype.setPrivateKey = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional bytes certificate = 5;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.HardwareIdentity.prototype.getCertificate = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes certificate = 5;
 * This is a type-conversion wrapper around `getCertificate()`
 * @return {string}
 */
proto.toit.model.HardwareIdentity.prototype.getCertificate_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCertificate()));
};


/**
 * optional bytes certificate = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCertificate()`
 * @return {!Uint8Array}
 */
proto.toit.model.HardwareIdentity.prototype.getCertificate_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCertificate()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.HardwareIdentity.prototype.setCertificate = function(value) {
  jspb.Message.setProto3BytesField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.HardwareIdentityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.HardwareIdentityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.HardwareIdentityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.HardwareIdentityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    factorySdkVersion: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flashedBy: msg.getFlashedBy_asB64(),
    iccid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    chipId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    factoryDeviceModel: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.HardwareIdentityInfo}
 */
proto.toit.model.HardwareIdentityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.HardwareIdentityInfo;
  return proto.toit.model.HardwareIdentityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.HardwareIdentityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.HardwareIdentityInfo}
 */
proto.toit.model.HardwareIdentityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactorySdkVersion(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFlashedBy(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIccid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChipId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactoryDeviceModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.HardwareIdentityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.HardwareIdentityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.HardwareIdentityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.HardwareIdentityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFactorySdkVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlashedBy_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getIccid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChipId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFactoryDeviceModel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string factory_sdk_version = 1;
 * @return {string}
 */
proto.toit.model.HardwareIdentityInfo.prototype.getFactorySdkVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.model.HardwareIdentityInfo.prototype.setFactorySdkVersion = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes flashed_by = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.HardwareIdentityInfo.prototype.getFlashedBy = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes flashed_by = 2;
 * This is a type-conversion wrapper around `getFlashedBy()`
 * @return {string}
 */
proto.toit.model.HardwareIdentityInfo.prototype.getFlashedBy_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFlashedBy()));
};


/**
 * optional bytes flashed_by = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFlashedBy()`
 * @return {!Uint8Array}
 */
proto.toit.model.HardwareIdentityInfo.prototype.getFlashedBy_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFlashedBy()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.HardwareIdentityInfo.prototype.setFlashedBy = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string iccid = 3;
 * @return {string}
 */
proto.toit.model.HardwareIdentityInfo.prototype.getIccid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.toit.model.HardwareIdentityInfo.prototype.setIccid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string chip_id = 4;
 * @return {string}
 */
proto.toit.model.HardwareIdentityInfo.prototype.getChipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.toit.model.HardwareIdentityInfo.prototype.setChipId = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string factory_device_model = 5;
 * @return {string}
 */
proto.toit.model.HardwareIdentityInfo.prototype.getFactoryDeviceModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.toit.model.HardwareIdentityInfo.prototype.setFactoryDeviceModel = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.HardwareInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.HardwareInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.HardwareInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.HardwareInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareId: msg.getHardwareId_asB64(),
    hardwareFqdn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationId: msg.getOrganizationId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.HardwareInfo}
 */
proto.toit.model.HardwareInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.HardwareInfo;
  return proto.toit.model.HardwareInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.HardwareInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.HardwareInfo}
 */
proto.toit.model.HardwareInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHardwareId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareFqdn(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.HardwareInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.HardwareInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.HardwareInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.HardwareInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getHardwareFqdn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional bytes hardware_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.HardwareInfo.prototype.getHardwareId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes hardware_id = 1;
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {string}
 */
proto.toit.model.HardwareInfo.prototype.getHardwareId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHardwareId()));
};


/**
 * optional bytes hardware_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {!Uint8Array}
 */
proto.toit.model.HardwareInfo.prototype.getHardwareId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHardwareId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.HardwareInfo.prototype.setHardwareId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string hardware_fqdn = 2;
 * @return {string}
 */
proto.toit.model.HardwareInfo.prototype.getHardwareFqdn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.toit.model.HardwareInfo.prototype.setHardwareFqdn = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes organization_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.HardwareInfo.prototype.getOrganizationId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes organization_id = 4;
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {string}
 */
proto.toit.model.HardwareInfo.prototype.getOrganizationId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOrganizationId()));
};


/**
 * optional bytes organization_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {!Uint8Array}
 */
proto.toit.model.HardwareInfo.prototype.getOrganizationId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOrganizationId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.HardwareInfo.prototype.setOrganizationId = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.HardwareToDeviceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.HardwareToDeviceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.HardwareToDeviceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.HardwareToDeviceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareId: msg.getHardwareId_asB64(),
    deviceId: msg.getDeviceId_asB64(),
    bound: (f = msg.getBound()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    unbound: (f = msg.getUnbound()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.HardwareToDeviceInfo}
 */
proto.toit.model.HardwareToDeviceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.HardwareToDeviceInfo;
  return proto.toit.model.HardwareToDeviceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.HardwareToDeviceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.HardwareToDeviceInfo}
 */
proto.toit.model.HardwareToDeviceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHardwareId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDeviceId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBound(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUnbound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.HardwareToDeviceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.HardwareToDeviceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.HardwareToDeviceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.HardwareToDeviceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getDeviceId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getBound();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUnbound();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes hardware_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.HardwareToDeviceInfo.prototype.getHardwareId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes hardware_id = 1;
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {string}
 */
proto.toit.model.HardwareToDeviceInfo.prototype.getHardwareId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHardwareId()));
};


/**
 * optional bytes hardware_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {!Uint8Array}
 */
proto.toit.model.HardwareToDeviceInfo.prototype.getHardwareId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHardwareId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.HardwareToDeviceInfo.prototype.setHardwareId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes device_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.HardwareToDeviceInfo.prototype.getDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes device_id = 2;
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {string}
 */
proto.toit.model.HardwareToDeviceInfo.prototype.getDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDeviceId()));
};


/**
 * optional bytes device_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {!Uint8Array}
 */
proto.toit.model.HardwareToDeviceInfo.prototype.getDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDeviceId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.HardwareToDeviceInfo.prototype.setDeviceId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp bound = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.HardwareToDeviceInfo.prototype.getBound = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.HardwareToDeviceInfo.prototype.setBound = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.HardwareToDeviceInfo.prototype.clearBound = function() {
  this.setBound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.HardwareToDeviceInfo.prototype.hasBound = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp unbound = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.HardwareToDeviceInfo.prototype.getUnbound = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.HardwareToDeviceInfo.prototype.setUnbound = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.HardwareToDeviceInfo.prototype.clearUnbound = function() {
  this.setUnbound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.HardwareToDeviceInfo.prototype.hasUnbound = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.toit.model.DeviceEvent.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: msg.getDeviceId_asB64(),
    eventId: msg.getEventId_asB64(),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    msg: jspb.Message.getFieldWithDefault(msg, 5, ""),
    data: msg.getData_asB64(),
    initiater: (f = msg.getInitiater()) && proto.toit.model.DeviceEvent.Initiater.toObject(includeInstance, f),
    informationList: jspb.Message.toObjectList(msg.getInformationList(),
    proto.toit.model.DeviceEvent.Info.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceEvent}
 */
proto.toit.model.DeviceEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceEvent;
  return proto.toit.model.DeviceEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceEvent}
 */
proto.toit.model.DeviceEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEventId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 4:
      var value = /** @type {!proto.toit.model.DeviceEvent.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsg(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 7:
      var value = new proto.toit.model.DeviceEvent.Initiater;
      reader.readMessage(value,proto.toit.model.DeviceEvent.Initiater.deserializeBinaryFromReader);
      msg.setInitiater(value);
      break;
    case 8:
      var value = new proto.toit.model.DeviceEvent.Info;
      reader.readMessage(value,proto.toit.model.DeviceEvent.Info.deserializeBinaryFromReader);
      msg.addInformation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getEventId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getInitiater();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.toit.model.DeviceEvent.Initiater.serializeBinaryToWriter
    );
  }
  f = message.getInformationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.toit.model.DeviceEvent.Info.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.toit.model.DeviceEvent.Type = {
  UNKNOWN: 0,
  CONFIG_CHANGE: 1,
  STATUS_CHANGE: 2,
  NEXT_ACTION: 3,
  DEVICE_ACTION: 4,
  QUEUE_OVERFLOW: 5,
  REQUEST_FILE: 6
};


/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.toit.model.DeviceEvent.Initiater.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.toit.model.DeviceEvent.Initiater.InitiaterCase = {
  INITIATER_NOT_SET: 0,
  DEVICE: 1,
  CONSOLE: 2,
  USER: 3
};

/**
 * @return {proto.toit.model.DeviceEvent.Initiater.InitiaterCase}
 */
proto.toit.model.DeviceEvent.Initiater.prototype.getInitiaterCase = function() {
  return /** @type {proto.toit.model.DeviceEvent.Initiater.InitiaterCase} */(jspb.Message.computeOneofCase(this, proto.toit.model.DeviceEvent.Initiater.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceEvent.Initiater.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceEvent.Initiater.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceEvent.Initiater} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Initiater.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && proto.toit.model.DeviceEvent.Initiater.Device.toObject(includeInstance, f),
    console: (f = msg.getConsole()) && proto.toit.model.DeviceEvent.Initiater.Console.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.toit.model.DeviceEvent.Initiater.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceEvent.Initiater}
 */
proto.toit.model.DeviceEvent.Initiater.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceEvent.Initiater;
  return proto.toit.model.DeviceEvent.Initiater.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceEvent.Initiater} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceEvent.Initiater}
 */
proto.toit.model.DeviceEvent.Initiater.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.toit.model.DeviceEvent.Initiater.Device;
      reader.readMessage(value,proto.toit.model.DeviceEvent.Initiater.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 2:
      var value = new proto.toit.model.DeviceEvent.Initiater.Console;
      reader.readMessage(value,proto.toit.model.DeviceEvent.Initiater.Console.deserializeBinaryFromReader);
      msg.setConsole(value);
      break;
    case 3:
      var value = new proto.toit.model.DeviceEvent.Initiater.User;
      reader.readMessage(value,proto.toit.model.DeviceEvent.Initiater.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.Initiater.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceEvent.Initiater.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceEvent.Initiater} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Initiater.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.toit.model.DeviceEvent.Initiater.Device.serializeBinaryToWriter
    );
  }
  f = message.getConsole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.toit.model.DeviceEvent.Initiater.Console.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.toit.model.DeviceEvent.Initiater.User.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceEvent.Initiater.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceEvent.Initiater.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceEvent.Initiater.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Initiater.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: msg.getDeviceId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceEvent.Initiater.Device}
 */
proto.toit.model.DeviceEvent.Initiater.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceEvent.Initiater.Device;
  return proto.toit.model.DeviceEvent.Initiater.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceEvent.Initiater.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceEvent.Initiater.Device}
 */
proto.toit.model.DeviceEvent.Initiater.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.Initiater.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceEvent.Initiater.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceEvent.Initiater.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Initiater.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes device_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.DeviceEvent.Initiater.Device.prototype.getDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes device_id = 1;
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {string}
 */
proto.toit.model.DeviceEvent.Initiater.Device.prototype.getDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDeviceId()));
};


/**
 * optional bytes device_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.Initiater.Device.prototype.getDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDeviceId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.DeviceEvent.Initiater.Device.prototype.setDeviceId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceEvent.Initiater.Console.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceEvent.Initiater.Console.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceEvent.Initiater.Console} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Initiater.Console.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceEvent.Initiater.Console}
 */
proto.toit.model.DeviceEvent.Initiater.Console.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceEvent.Initiater.Console;
  return proto.toit.model.DeviceEvent.Initiater.Console.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceEvent.Initiater.Console} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceEvent.Initiater.Console}
 */
proto.toit.model.DeviceEvent.Initiater.Console.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.Initiater.Console.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceEvent.Initiater.Console.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceEvent.Initiater.Console} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Initiater.Console.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceEvent.Initiater.User.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceEvent.Initiater.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceEvent.Initiater.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Initiater.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: msg.getUserId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceEvent.Initiater.User}
 */
proto.toit.model.DeviceEvent.Initiater.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceEvent.Initiater.User;
  return proto.toit.model.DeviceEvent.Initiater.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceEvent.Initiater.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceEvent.Initiater.User}
 */
proto.toit.model.DeviceEvent.Initiater.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.Initiater.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceEvent.Initiater.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceEvent.Initiater.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Initiater.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes user_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.DeviceEvent.Initiater.User.prototype.getUserId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes user_id = 1;
 * This is a type-conversion wrapper around `getUserId()`
 * @return {string}
 */
proto.toit.model.DeviceEvent.Initiater.User.prototype.getUserId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getUserId()));
};


/**
 * optional bytes user_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getUserId()`
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.Initiater.User.prototype.getUserId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getUserId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.DeviceEvent.Initiater.User.prototype.setUserId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional Device device = 1;
 * @return {?proto.toit.model.DeviceEvent.Initiater.Device}
 */
proto.toit.model.DeviceEvent.Initiater.prototype.getDevice = function() {
  return /** @type{?proto.toit.model.DeviceEvent.Initiater.Device} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceEvent.Initiater.Device, 1));
};


/** @param {?proto.toit.model.DeviceEvent.Initiater.Device|undefined} value */
proto.toit.model.DeviceEvent.Initiater.prototype.setDevice = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.toit.model.DeviceEvent.Initiater.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceEvent.Initiater.prototype.clearDevice = function() {
  this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceEvent.Initiater.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Console console = 2;
 * @return {?proto.toit.model.DeviceEvent.Initiater.Console}
 */
proto.toit.model.DeviceEvent.Initiater.prototype.getConsole = function() {
  return /** @type{?proto.toit.model.DeviceEvent.Initiater.Console} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceEvent.Initiater.Console, 2));
};


/** @param {?proto.toit.model.DeviceEvent.Initiater.Console|undefined} value */
proto.toit.model.DeviceEvent.Initiater.prototype.setConsole = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.toit.model.DeviceEvent.Initiater.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceEvent.Initiater.prototype.clearConsole = function() {
  this.setConsole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceEvent.Initiater.prototype.hasConsole = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional User user = 3;
 * @return {?proto.toit.model.DeviceEvent.Initiater.User}
 */
proto.toit.model.DeviceEvent.Initiater.prototype.getUser = function() {
  return /** @type{?proto.toit.model.DeviceEvent.Initiater.User} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceEvent.Initiater.User, 3));
};


/** @param {?proto.toit.model.DeviceEvent.Initiater.User|undefined} value */
proto.toit.model.DeviceEvent.Initiater.prototype.setUser = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.toit.model.DeviceEvent.Initiater.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceEvent.Initiater.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceEvent.Initiater.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceEvent.Info.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceEvent.Info.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceEvent.Info} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Info.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && proto.toit.model.DeviceEvent.Info.Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceEvent.Info}
 */
proto.toit.model.DeviceEvent.Info.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceEvent.Info;
  return proto.toit.model.DeviceEvent.Info.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceEvent.Info} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceEvent.Info}
 */
proto.toit.model.DeviceEvent.Info.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = new proto.toit.model.DeviceEvent.Info.Value;
      reader.readMessage(value,proto.toit.model.DeviceEvent.Info.Value.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.Info.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceEvent.Info.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceEvent.Info} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Info.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.toit.model.DeviceEvent.Info.Value.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.toit.model.DeviceEvent.Info.Value.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.toit.model.DeviceEvent.Info.Value.ValueCase = {
  VALUE_NOT_SET: 0,
  PRIMITIVE: 1,
  DIFF: 2,
  ERROR: 3
};

/**
 * @return {proto.toit.model.DeviceEvent.Info.Value.ValueCase}
 */
proto.toit.model.DeviceEvent.Info.Value.prototype.getValueCase = function() {
  return /** @type {proto.toit.model.DeviceEvent.Info.Value.ValueCase} */(jspb.Message.computeOneofCase(this, proto.toit.model.DeviceEvent.Info.Value.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceEvent.Info.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceEvent.Info.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceEvent.Info.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Info.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    primitive: (f = msg.getPrimitive()) && proto.toit.model.DeviceEvent.Info.Value.Primitive.toObject(includeInstance, f),
    diff: (f = msg.getDiff()) && proto.toit.model.DeviceEvent.Info.Value.Diff.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.toit.model.DeviceEvent.Info.Value.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceEvent.Info.Value}
 */
proto.toit.model.DeviceEvent.Info.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceEvent.Info.Value;
  return proto.toit.model.DeviceEvent.Info.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceEvent.Info.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceEvent.Info.Value}
 */
proto.toit.model.DeviceEvent.Info.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.toit.model.DeviceEvent.Info.Value.Primitive;
      reader.readMessage(value,proto.toit.model.DeviceEvent.Info.Value.Primitive.deserializeBinaryFromReader);
      msg.setPrimitive(value);
      break;
    case 2:
      var value = new proto.toit.model.DeviceEvent.Info.Value.Diff;
      reader.readMessage(value,proto.toit.model.DeviceEvent.Info.Value.Diff.deserializeBinaryFromReader);
      msg.setDiff(value);
      break;
    case 3:
      var value = new proto.toit.model.DeviceEvent.Info.Value.Error;
      reader.readMessage(value,proto.toit.model.DeviceEvent.Info.Value.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.Info.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceEvent.Info.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceEvent.Info.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Info.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrimitive();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.toit.model.DeviceEvent.Info.Value.Primitive.serializeBinaryToWriter
    );
  }
  f = message.getDiff();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.toit.model.DeviceEvent.Info.Value.Diff.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.toit.model.DeviceEvent.Info.Value.Error.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceEvent.Info.Value.Primitive.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceEvent.Info.Value.Primitive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceEvent.Info.Value.Primitive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Info.Value.Primitive.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: msg.getValue_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceEvent.Info.Value.Primitive}
 */
proto.toit.model.DeviceEvent.Info.Value.Primitive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceEvent.Info.Value.Primitive;
  return proto.toit.model.DeviceEvent.Info.Value.Primitive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceEvent.Info.Value.Primitive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceEvent.Info.Value.Primitive}
 */
proto.toit.model.DeviceEvent.Info.Value.Primitive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.toit.model.DeviceEvent.Info.Value.Primitive.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.Info.Value.Primitive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceEvent.Info.Value.Primitive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceEvent.Info.Value.Primitive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Info.Value.Primitive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.toit.model.DeviceEvent.Info.Value.Primitive.Type = {
  INVALID: 0,
  JSON: 1,
  STRING: 2
};

/**
 * optional Type type = 1;
 * @return {!proto.toit.model.DeviceEvent.Info.Value.Primitive.Type}
 */
proto.toit.model.DeviceEvent.Info.Value.Primitive.prototype.getType = function() {
  return /** @type {!proto.toit.model.DeviceEvent.Info.Value.Primitive.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.toit.model.DeviceEvent.Info.Value.Primitive.Type} value */
proto.toit.model.DeviceEvent.Info.Value.Primitive.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes value = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.DeviceEvent.Info.Value.Primitive.prototype.getValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes value = 2;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.toit.model.DeviceEvent.Info.Value.Primitive.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.Info.Value.Primitive.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.DeviceEvent.Info.Value.Primitive.prototype.setValue = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceEvent.Info.Value.Diff.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceEvent.Info.Value.Diff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceEvent.Info.Value.Diff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Info.Value.Diff.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && proto.toit.model.DeviceEvent.Info.Value.Primitive.toObject(includeInstance, f),
    to: (f = msg.getTo()) && proto.toit.model.DeviceEvent.Info.Value.Primitive.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceEvent.Info.Value.Diff}
 */
proto.toit.model.DeviceEvent.Info.Value.Diff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceEvent.Info.Value.Diff;
  return proto.toit.model.DeviceEvent.Info.Value.Diff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceEvent.Info.Value.Diff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceEvent.Info.Value.Diff}
 */
proto.toit.model.DeviceEvent.Info.Value.Diff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.toit.model.DeviceEvent.Info.Value.Primitive;
      reader.readMessage(value,proto.toit.model.DeviceEvent.Info.Value.Primitive.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new proto.toit.model.DeviceEvent.Info.Value.Primitive;
      reader.readMessage(value,proto.toit.model.DeviceEvent.Info.Value.Primitive.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.Info.Value.Diff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceEvent.Info.Value.Diff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceEvent.Info.Value.Diff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Info.Value.Diff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.toit.model.DeviceEvent.Info.Value.Primitive.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.toit.model.DeviceEvent.Info.Value.Primitive.serializeBinaryToWriter
    );
  }
};


/**
 * optional Primitive from = 1;
 * @return {?proto.toit.model.DeviceEvent.Info.Value.Primitive}
 */
proto.toit.model.DeviceEvent.Info.Value.Diff.prototype.getFrom = function() {
  return /** @type{?proto.toit.model.DeviceEvent.Info.Value.Primitive} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceEvent.Info.Value.Primitive, 1));
};


/** @param {?proto.toit.model.DeviceEvent.Info.Value.Primitive|undefined} value */
proto.toit.model.DeviceEvent.Info.Value.Diff.prototype.setFrom = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceEvent.Info.Value.Diff.prototype.clearFrom = function() {
  this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceEvent.Info.Value.Diff.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Primitive to = 2;
 * @return {?proto.toit.model.DeviceEvent.Info.Value.Primitive}
 */
proto.toit.model.DeviceEvent.Info.Value.Diff.prototype.getTo = function() {
  return /** @type{?proto.toit.model.DeviceEvent.Info.Value.Primitive} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceEvent.Info.Value.Primitive, 2));
};


/** @param {?proto.toit.model.DeviceEvent.Info.Value.Primitive|undefined} value */
proto.toit.model.DeviceEvent.Info.Value.Diff.prototype.setTo = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceEvent.Info.Value.Diff.prototype.clearTo = function() {
  this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceEvent.Info.Value.Diff.prototype.hasTo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceEvent.Info.Value.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceEvent.Info.Value.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceEvent.Info.Value.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Info.Value.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceEvent.Info.Value.Error}
 */
proto.toit.model.DeviceEvent.Info.Value.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceEvent.Info.Value.Error;
  return proto.toit.model.DeviceEvent.Info.Value.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceEvent.Info.Value.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceEvent.Info.Value.Error}
 */
proto.toit.model.DeviceEvent.Info.Value.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.Info.Value.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceEvent.Info.Value.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceEvent.Info.Value.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceEvent.Info.Value.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string error = 1;
 * @return {string}
 */
proto.toit.model.DeviceEvent.Info.Value.Error.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.model.DeviceEvent.Info.Value.Error.prototype.setError = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Primitive primitive = 1;
 * @return {?proto.toit.model.DeviceEvent.Info.Value.Primitive}
 */
proto.toit.model.DeviceEvent.Info.Value.prototype.getPrimitive = function() {
  return /** @type{?proto.toit.model.DeviceEvent.Info.Value.Primitive} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceEvent.Info.Value.Primitive, 1));
};


/** @param {?proto.toit.model.DeviceEvent.Info.Value.Primitive|undefined} value */
proto.toit.model.DeviceEvent.Info.Value.prototype.setPrimitive = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.toit.model.DeviceEvent.Info.Value.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceEvent.Info.Value.prototype.clearPrimitive = function() {
  this.setPrimitive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceEvent.Info.Value.prototype.hasPrimitive = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Diff diff = 2;
 * @return {?proto.toit.model.DeviceEvent.Info.Value.Diff}
 */
proto.toit.model.DeviceEvent.Info.Value.prototype.getDiff = function() {
  return /** @type{?proto.toit.model.DeviceEvent.Info.Value.Diff} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceEvent.Info.Value.Diff, 2));
};


/** @param {?proto.toit.model.DeviceEvent.Info.Value.Diff|undefined} value */
proto.toit.model.DeviceEvent.Info.Value.prototype.setDiff = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.toit.model.DeviceEvent.Info.Value.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceEvent.Info.Value.prototype.clearDiff = function() {
  this.setDiff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceEvent.Info.Value.prototype.hasDiff = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Error error = 3;
 * @return {?proto.toit.model.DeviceEvent.Info.Value.Error}
 */
proto.toit.model.DeviceEvent.Info.Value.prototype.getError = function() {
  return /** @type{?proto.toit.model.DeviceEvent.Info.Value.Error} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceEvent.Info.Value.Error, 3));
};


/** @param {?proto.toit.model.DeviceEvent.Info.Value.Error|undefined} value */
proto.toit.model.DeviceEvent.Info.Value.prototype.setError = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.toit.model.DeviceEvent.Info.Value.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceEvent.Info.Value.prototype.clearError = function() {
  this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceEvent.Info.Value.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.toit.model.DeviceEvent.Info.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.model.DeviceEvent.Info.prototype.setKey = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Value value = 2;
 * @return {?proto.toit.model.DeviceEvent.Info.Value}
 */
proto.toit.model.DeviceEvent.Info.prototype.getValue = function() {
  return /** @type{?proto.toit.model.DeviceEvent.Info.Value} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceEvent.Info.Value, 2));
};


/** @param {?proto.toit.model.DeviceEvent.Info.Value|undefined} value */
proto.toit.model.DeviceEvent.Info.prototype.setValue = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceEvent.Info.prototype.clearValue = function() {
  this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceEvent.Info.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes device_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.DeviceEvent.prototype.getDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes device_id = 1;
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {string}
 */
proto.toit.model.DeviceEvent.prototype.getDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDeviceId()));
};


/**
 * optional bytes device_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.prototype.getDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDeviceId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.DeviceEvent.prototype.setDeviceId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes event_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.DeviceEvent.prototype.getEventId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes event_id = 2;
 * This is a type-conversion wrapper around `getEventId()`
 * @return {string}
 */
proto.toit.model.DeviceEvent.prototype.getEventId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEventId()));
};


/**
 * optional bytes event_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEventId()`
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.prototype.getEventId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEventId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.DeviceEvent.prototype.setEventId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.DeviceEvent.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.DeviceEvent.prototype.setCreated = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceEvent.prototype.clearCreated = function() {
  this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceEvent.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Type type = 4;
 * @return {!proto.toit.model.DeviceEvent.Type}
 */
proto.toit.model.DeviceEvent.prototype.getType = function() {
  return /** @type {!proto.toit.model.DeviceEvent.Type} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.toit.model.DeviceEvent.Type} value */
proto.toit.model.DeviceEvent.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string msg = 5;
 * @return {string}
 */
proto.toit.model.DeviceEvent.prototype.getMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.toit.model.DeviceEvent.prototype.setMsg = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bytes data = 6;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.DeviceEvent.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes data = 6;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.toit.model.DeviceEvent.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceEvent.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.DeviceEvent.prototype.setData = function(value) {
  jspb.Message.setProto3BytesField(this, 6, value);
};


/**
 * optional Initiater initiater = 7;
 * @return {?proto.toit.model.DeviceEvent.Initiater}
 */
proto.toit.model.DeviceEvent.prototype.getInitiater = function() {
  return /** @type{?proto.toit.model.DeviceEvent.Initiater} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.DeviceEvent.Initiater, 7));
};


/** @param {?proto.toit.model.DeviceEvent.Initiater|undefined} value */
proto.toit.model.DeviceEvent.prototype.setInitiater = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.DeviceEvent.prototype.clearInitiater = function() {
  this.setInitiater(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.DeviceEvent.prototype.hasInitiater = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated Info information = 8;
 * @return {!Array<!proto.toit.model.DeviceEvent.Info>}
 */
proto.toit.model.DeviceEvent.prototype.getInformationList = function() {
  return /** @type{!Array<!proto.toit.model.DeviceEvent.Info>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.toit.model.DeviceEvent.Info, 8));
};


/** @param {!Array<!proto.toit.model.DeviceEvent.Info>} value */
proto.toit.model.DeviceEvent.prototype.setInformationList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.toit.model.DeviceEvent.Info=} opt_value
 * @param {number=} opt_index
 * @return {!proto.toit.model.DeviceEvent.Info}
 */
proto.toit.model.DeviceEvent.prototype.addInformation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.toit.model.DeviceEvent.Info, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.toit.model.DeviceEvent.prototype.clearInformationList = function() {
  this.setInformationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.DeviceModelSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.DeviceModelSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.DeviceModelSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceModelSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    parametersMap: (f = msg.getParametersMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.DeviceModelSettings}
 */
proto.toit.model.DeviceModelSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.DeviceModelSettings;
  return proto.toit.model.DeviceModelSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.DeviceModelSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.DeviceModelSettings}
 */
proto.toit.model.DeviceModelSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getParametersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt32, null, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.DeviceModelSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.DeviceModelSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.DeviceModelSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.DeviceModelSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParametersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt32);
  }
};


/**
 * map<string, int32> parameters = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.toit.model.DeviceModelSettings.prototype.getParametersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.toit.model.DeviceModelSettings.prototype.clearParametersMap = function() {
  this.getParametersMap().clear();
};


/**
 * @enum {number}
 */
proto.toit.model.DeviceConnectionType = {
  DEVICE_CONNECTION_TYPE_UNKNOWN: 0,
  DEVICE_CONNECTION_TYPE_WIFI: 1,
  DEVICE_CONNECTION_TYPE_NBIOT: 2,
  DEVICE_CONNECTION_TYPE_OS: 3
};

/**
 * @enum {number}
 */
proto.toit.model.DeviceChangeType = {
  DEVICE_CHANGE_UNKNOWN: 0,
  DEVICE_CHANGE_ADDED: 1,
  DEVICE_CHANGE_DELETED: 2,
  DEVICE_CHANGE_CHANGED: 3,
  DEVICE_CHANGE_HEARTBEAT: 4,
  DEVICE_CHANGE_CONNECTED: 5,
  DEVICE_CHANGE_DISCONNECTED: 6
};

/**
 * @enum {number}
 */
proto.toit.model.DeviceSessionChangeType = {
  UNKNOWN_TYPE: 0,
  CONNECTED: 1,
  DISCONNECTED: 2
};

/**
 * @enum {number}
 */
proto.toit.model.DeviceAction = {
  DEVICE_ACTION_UNKNOWN: 0,
  DEVICE_ACTION_NOP: 1,
  DEVICE_ACTION_REBOOT: 2,
  DEVICE_ACTION_NEW_EPOCH: 3,
  DEVICE_ACTION_REPORT_CONFIG: 4,
  DEVICE_ACTION_FACTORY_PROMOTE: 5,
  DEVICE_ACTION_UPDATE_CONFIG: 6,
  DEVICE_ACTION_UPDATE_FIRMWARE: 7,
  DEVICE_ACTION_FACTORY_RESET: 8,
  DEVICE_ACTION_RECONCILE: 9,
  DEVICE_ACTION_UPDATE_PARTIAL_FIRMWARE: 10,
  DEVICE_ACTION_REPORT_MODULES: 11,
  DEVICE_ACTION_UPDATE_MODULE_CELLULAR: 12
};

/**
 * @enum {number}
 */
proto.toit.model.DeviceModuleType = {
  UNKNOWN: 0,
  CELLULAR: 1
};

goog.object.extend(exports, proto.toit.model);
