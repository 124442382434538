import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
    },
    border: {
      borderBottom: "1px solid black",
      width: "100%;",
    },
    content: {
      padding: "0 10px 0 10px",
    },
  });

interface DividerProps extends WithStyles<typeof styles> {
  title: string;
}

function CustomDivider(props: DividerProps): JSX.Element {
  return (
    <div className={props.classes.container}>
      <div className={props.classes.border} />
      <span className={props.classes.content}>{props.title}</span>
      <div className={props.classes.border} />
    </div>
  );
}

export default withStyles(styles)(CustomDivider);
