import { MuiThemeProvider } from "@material-ui/core";
import CookieConsent from "@toitware/cookie-consent";
import React from "react";
import queryString from "query-string";
import theme from "./assets/theme/theme";
import MainView from "./components/MainView";
import ProtoServiceClient from "./service/proto/proto-service-client";
import { ServiceContext } from "./service/service-context";

function getHeadMeta(name: string): string {
  const elements = document.getElementsByName(name);
  if (elements.length === 1) {
    return (elements[0] as HTMLMetaElement).content;
  }
  return "";
}

const query = queryString.parse(window.location.search);
export const developmentMode =
  (window.location.href.startsWith("http://localhost") ||
    window.location.href.startsWith("https://localhost") ||
    window.location.href.startsWith("https://auth.local.toit.io") ||
    window.location.href.startsWith("https://web-auth.infra.toit.io")) &&
  query["devMode"] !== "off" &&
  query["devMode"] !== "0" &&
  query["devMode"] !== "false";

export const API_URL = getHeadMeta("toit-api-url");
export const OAUTH_URL = getHeadMeta("toit-oauth-url");
export const CONSOLE_URL = getHeadMeta("toit-console-url");
export const SEGMENT_KEY = getHeadMeta("segment-key");

let RedditTrackingSetup = false;
const setupRedditTracking = () => {
  if (RedditTrackingSetup) {
    return;
  }
  RedditTrackingSetup = true;
  // Redirect to analytics requests to reddit.
  const forReddit = (options?: SegmentAnalytics.SegmentOpts): boolean => {
    const integrations = options && (options.integrations as { Reddit?: boolean } | undefined);
    return !!(integrations && integrations.Reddit);
  };

  if (typeof window !== "undefined") {
    analytics.on("track", (event, properties, options) => {
      if (forReddit(options)) {
        window.rdt("track", event);
      }
    });
    analytics.on("page", () => {
      window.rdt("track", "PageVisit");
    });
  }
};
setupRedditTracking();

const serviceClient = new ProtoServiceClient(API_URL);
export const context = new ServiceContext(serviceClient);

class App extends React.Component {
  componentDidMount(): void {
    console.log("API_URL", API_URL);
    console.log("OAUTH_URL", OAUTH_URL);
    console.log("CONSOLE_URL", CONSOLE_URL);
  }

  render(): JSX.Element {
    return (
      <MuiThemeProvider theme={theme}>
        <MainView />
        <CookieConsent
          segmentKey={SEGMENT_KEY}
          show={false}
          changeConsent={false}
          onAnalyticsReady={() => {
            window.redditSnippetLoader("t2_brvtmsx5");
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default App;
