/* eslint-disable */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.toit.model.pubsub.Envelope', null, global);
goog.exportSymbol('proto.toit.model.pubsub.Message', null, global);
goog.exportSymbol('proto.toit.model.pubsub.Publisher', null, global);
goog.exportSymbol('proto.toit.model.pubsub.Publisher.Device', null, global);
goog.exportSymbol('proto.toit.model.pubsub.Publisher.External', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.pubsub.Envelope = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.pubsub.Envelope, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.pubsub.Envelope.displayName = 'proto.toit.model.pubsub.Envelope';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.pubsub.Publisher = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.toit.model.pubsub.Publisher.oneofGroups_);
};
goog.inherits(proto.toit.model.pubsub.Publisher, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.pubsub.Publisher.displayName = 'proto.toit.model.pubsub.Publisher';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.pubsub.Publisher.External = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.pubsub.Publisher.External, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.pubsub.Publisher.External.displayName = 'proto.toit.model.pubsub.Publisher.External';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.pubsub.Publisher.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.pubsub.Publisher.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.pubsub.Publisher.Device.displayName = 'proto.toit.model.pubsub.Publisher.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.pubsub.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.pubsub.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.pubsub.Message.displayName = 'proto.toit.model.pubsub.Message';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.pubsub.Envelope.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.pubsub.Envelope.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.pubsub.Envelope} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.pubsub.Envelope.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    message: (f = msg.getMessage()) && proto.toit.model.pubsub.Message.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.pubsub.Envelope}
 */
proto.toit.model.pubsub.Envelope.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.pubsub.Envelope;
  return proto.toit.model.pubsub.Envelope.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.pubsub.Envelope} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.pubsub.Envelope}
 */
proto.toit.model.pubsub.Envelope.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.toit.model.pubsub.Message;
      reader.readMessage(value,proto.toit.model.pubsub.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.pubsub.Envelope.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.pubsub.Envelope.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.pubsub.Envelope} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.pubsub.Envelope.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.toit.model.pubsub.Message.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.pubsub.Envelope.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.toit.model.pubsub.Envelope.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.toit.model.pubsub.Envelope.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.pubsub.Envelope.prototype.setId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional Message message = 2;
 * @return {?proto.toit.model.pubsub.Message}
 */
proto.toit.model.pubsub.Envelope.prototype.getMessage = function() {
  return /** @type{?proto.toit.model.pubsub.Message} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.pubsub.Message, 2));
};


/** @param {?proto.toit.model.pubsub.Message|undefined} value */
proto.toit.model.pubsub.Envelope.prototype.setMessage = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.pubsub.Envelope.prototype.clearMessage = function() {
  this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.pubsub.Envelope.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.toit.model.pubsub.Publisher.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.toit.model.pubsub.Publisher.PublisherCase = {
  PUBLISHER_NOT_SET: 0,
  DEVICE: 1,
  EXTERNAL: 2
};

/**
 * @return {proto.toit.model.pubsub.Publisher.PublisherCase}
 */
proto.toit.model.pubsub.Publisher.prototype.getPublisherCase = function() {
  return /** @type {proto.toit.model.pubsub.Publisher.PublisherCase} */(jspb.Message.computeOneofCase(this, proto.toit.model.pubsub.Publisher.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.pubsub.Publisher.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.pubsub.Publisher.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.pubsub.Publisher} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.pubsub.Publisher.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && proto.toit.model.pubsub.Publisher.Device.toObject(includeInstance, f),
    external: (f = msg.getExternal()) && proto.toit.model.pubsub.Publisher.External.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.pubsub.Publisher}
 */
proto.toit.model.pubsub.Publisher.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.pubsub.Publisher;
  return proto.toit.model.pubsub.Publisher.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.pubsub.Publisher} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.pubsub.Publisher}
 */
proto.toit.model.pubsub.Publisher.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.toit.model.pubsub.Publisher.Device;
      reader.readMessage(value,proto.toit.model.pubsub.Publisher.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 2:
      var value = new proto.toit.model.pubsub.Publisher.External;
      reader.readMessage(value,proto.toit.model.pubsub.Publisher.External.deserializeBinaryFromReader);
      msg.setExternal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.pubsub.Publisher.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.pubsub.Publisher.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.pubsub.Publisher} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.pubsub.Publisher.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.toit.model.pubsub.Publisher.Device.serializeBinaryToWriter
    );
  }
  f = message.getExternal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.toit.model.pubsub.Publisher.External.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.pubsub.Publisher.External.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.pubsub.Publisher.External.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.pubsub.Publisher.External} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.pubsub.Publisher.External.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.pubsub.Publisher.External}
 */
proto.toit.model.pubsub.Publisher.External.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.pubsub.Publisher.External;
  return proto.toit.model.pubsub.Publisher.External.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.pubsub.Publisher.External} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.pubsub.Publisher.External}
 */
proto.toit.model.pubsub.Publisher.External.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.pubsub.Publisher.External.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.pubsub.Publisher.External.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.pubsub.Publisher.External} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.pubsub.Publisher.External.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.toit.model.pubsub.Publisher.External.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.model.pubsub.Publisher.External.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.pubsub.Publisher.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.pubsub.Publisher.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.pubsub.Publisher.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareId: msg.getHardwareId_asB64(),
    deviceId: msg.getDeviceId_asB64(),
    eventId: msg.getEventId_asB64(),
    jobId: msg.getJobId_asB64(),
    writtenAt: (f = msg.getWrittenAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.pubsub.Publisher.Device}
 */
proto.toit.model.pubsub.Publisher.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.pubsub.Publisher.Device;
  return proto.toit.model.pubsub.Publisher.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.pubsub.Publisher.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.pubsub.Publisher.Device}
 */
proto.toit.model.pubsub.Publisher.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHardwareId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDeviceId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEventId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setJobId(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setWrittenAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.pubsub.Publisher.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.pubsub.Publisher.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.pubsub.Publisher.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getDeviceId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getEventId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getJobId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getWrittenAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes hardware_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getHardwareId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes hardware_id = 1;
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {string}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getHardwareId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHardwareId()));
};


/**
 * optional bytes hardware_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {!Uint8Array}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getHardwareId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHardwareId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.pubsub.Publisher.Device.prototype.setHardwareId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes device_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes device_id = 2;
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {string}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDeviceId()));
};


/**
 * optional bytes device_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {!Uint8Array}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDeviceId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.pubsub.Publisher.Device.prototype.setDeviceId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes event_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getEventId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes event_id = 3;
 * This is a type-conversion wrapper around `getEventId()`
 * @return {string}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getEventId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEventId()));
};


/**
 * optional bytes event_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEventId()`
 * @return {!Uint8Array}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getEventId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEventId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.pubsub.Publisher.Device.prototype.setEventId = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes job_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getJobId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes job_id = 4;
 * This is a type-conversion wrapper around `getJobId()`
 * @return {string}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getJobId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getJobId()));
};


/**
 * optional bytes job_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getJobId()`
 * @return {!Uint8Array}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getJobId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getJobId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.pubsub.Publisher.Device.prototype.setJobId = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp written_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.getWrittenAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.pubsub.Publisher.Device.prototype.setWrittenAt = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.pubsub.Publisher.Device.prototype.clearWrittenAt = function() {
  this.setWrittenAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.pubsub.Publisher.Device.prototype.hasWrittenAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Device device = 1;
 * @return {?proto.toit.model.pubsub.Publisher.Device}
 */
proto.toit.model.pubsub.Publisher.prototype.getDevice = function() {
  return /** @type{?proto.toit.model.pubsub.Publisher.Device} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.pubsub.Publisher.Device, 1));
};


/** @param {?proto.toit.model.pubsub.Publisher.Device|undefined} value */
proto.toit.model.pubsub.Publisher.prototype.setDevice = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.toit.model.pubsub.Publisher.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.pubsub.Publisher.prototype.clearDevice = function() {
  this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.pubsub.Publisher.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional External external = 2;
 * @return {?proto.toit.model.pubsub.Publisher.External}
 */
proto.toit.model.pubsub.Publisher.prototype.getExternal = function() {
  return /** @type{?proto.toit.model.pubsub.Publisher.External} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.pubsub.Publisher.External, 2));
};


/** @param {?proto.toit.model.pubsub.Publisher.External|undefined} value */
proto.toit.model.pubsub.Publisher.prototype.setExternal = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.toit.model.pubsub.Publisher.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.pubsub.Publisher.prototype.clearExternal = function() {
  this.setExternal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.pubsub.Publisher.prototype.hasExternal = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.pubsub.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.pubsub.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.pubsub.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.pubsub.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publisher: (f = msg.getPublisher()) && proto.toit.model.pubsub.Publisher.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.pubsub.Message}
 */
proto.toit.model.pubsub.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.pubsub.Message;
  return proto.toit.model.pubsub.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.pubsub.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.pubsub.Message}
 */
proto.toit.model.pubsub.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 2:
      var value = new proto.toit.model.pubsub.Publisher;
      reader.readMessage(value,proto.toit.model.pubsub.Publisher.deserializeBinaryFromReader);
      msg.setPublisher(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.pubsub.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.pubsub.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.pubsub.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.pubsub.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublisher();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.toit.model.pubsub.Publisher.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional string topic = 1;
 * @return {string}
 */
proto.toit.model.pubsub.Message.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.model.pubsub.Message.prototype.setTopic = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Publisher publisher = 2;
 * @return {?proto.toit.model.pubsub.Publisher}
 */
proto.toit.model.pubsub.Message.prototype.getPublisher = function() {
  return /** @type{?proto.toit.model.pubsub.Publisher} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.pubsub.Publisher, 2));
};


/** @param {?proto.toit.model.pubsub.Publisher|undefined} value */
proto.toit.model.pubsub.Message.prototype.setPublisher = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.pubsub.Message.prototype.clearPublisher = function() {
  this.setPublisher(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.pubsub.Message.prototype.hasPublisher = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.pubsub.Message.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.pubsub.Message.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.pubsub.Message.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.pubsub.Message.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes data = 4;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.pubsub.Message.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes data = 4;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.toit.model.pubsub.Message.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.toit.model.pubsub.Message.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.pubsub.Message.prototype.setData = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};


goog.object.extend(exports, proto.toit.model.pubsub);
