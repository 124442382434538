/* eslint-disable */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.toit.model.CounterMetricData', null, global);
goog.exportSymbol('proto.toit.model.GuageMetricData', null, global);
goog.exportSymbol('proto.toit.model.HistogramMetricData', null, global);
goog.exportSymbol('proto.toit.model.LogData', null, global);
goog.exportSymbol('proto.toit.model.LogData.Level', null, global);
goog.exportSymbol('proto.toit.model.LogData.Type', null, global);
goog.exportSymbol('proto.toit.model.MetricsData', null, global);
goog.exportSymbol('proto.toit.model.MetricsData.Level', null, global);
goog.exportSymbol('proto.toit.model.PlotMetricData', null, global);
goog.exportSymbol('proto.toit.model.TopicData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.MetricsData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.toit.model.MetricsData.repeatedFields_, null);
};
goog.inherits(proto.toit.model.MetricsData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.MetricsData.displayName = 'proto.toit.model.MetricsData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.GuageMetricData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.GuageMetricData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.GuageMetricData.displayName = 'proto.toit.model.GuageMetricData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.CounterMetricData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.CounterMetricData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.CounterMetricData.displayName = 'proto.toit.model.CounterMetricData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.PlotMetricData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.PlotMetricData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.PlotMetricData.displayName = 'proto.toit.model.PlotMetricData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.HistogramMetricData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.HistogramMetricData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.HistogramMetricData.displayName = 'proto.toit.model.HistogramMetricData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.TopicData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.TopicData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.TopicData.displayName = 'proto.toit.model.TopicData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.LogData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.toit.model.LogData.repeatedFields_, null);
};
goog.inherits(proto.toit.model.LogData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.LogData.displayName = 'proto.toit.model.LogData';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.toit.model.MetricsData.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.MetricsData.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.MetricsData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.MetricsData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.MetricsData.toObject = function(includeInstance, msg) {
  var f, obj = {
    namesList: msg.getNamesList_asB64(),
    gaugesList: jspb.Message.toObjectList(msg.getGaugesList(),
    proto.toit.model.GuageMetricData.toObject, includeInstance),
    countersList: jspb.Message.toObjectList(msg.getCountersList(),
    proto.toit.model.CounterMetricData.toObject, includeInstance),
    plotsList: jspb.Message.toObjectList(msg.getPlotsList(),
    proto.toit.model.PlotMetricData.toObject, includeInstance),
    histogramsList: jspb.Message.toObjectList(msg.getHistogramsList(),
    proto.toit.model.HistogramMetricData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.MetricsData}
 */
proto.toit.model.MetricsData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.MetricsData;
  return proto.toit.model.MetricsData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.MetricsData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.MetricsData}
 */
proto.toit.model.MetricsData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addNames(value);
      break;
    case 2:
      var value = new proto.toit.model.GuageMetricData;
      reader.readMessage(value,proto.toit.model.GuageMetricData.deserializeBinaryFromReader);
      msg.addGauges(value);
      break;
    case 3:
      var value = new proto.toit.model.CounterMetricData;
      reader.readMessage(value,proto.toit.model.CounterMetricData.deserializeBinaryFromReader);
      msg.addCounters(value);
      break;
    case 4:
      var value = new proto.toit.model.PlotMetricData;
      reader.readMessage(value,proto.toit.model.PlotMetricData.deserializeBinaryFromReader);
      msg.addPlots(value);
      break;
    case 5:
      var value = new proto.toit.model.HistogramMetricData;
      reader.readMessage(value,proto.toit.model.HistogramMetricData.deserializeBinaryFromReader);
      msg.addHistograms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.MetricsData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.MetricsData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.MetricsData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.MetricsData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamesList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      1,
      f
    );
  }
  f = message.getGaugesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.toit.model.GuageMetricData.serializeBinaryToWriter
    );
  }
  f = message.getCountersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.toit.model.CounterMetricData.serializeBinaryToWriter
    );
  }
  f = message.getPlotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.toit.model.PlotMetricData.serializeBinaryToWriter
    );
  }
  f = message.getHistogramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.toit.model.HistogramMetricData.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.toit.model.MetricsData.Level = {
  DEBUG_LEVEL: 0,
  INFO_LEVEL: 5,
  CRITICAL_LEVEL: 10
};

/**
 * repeated bytes names = 1;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.toit.model.MetricsData.prototype.getNamesList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * repeated bytes names = 1;
 * This is a type-conversion wrapper around `getNamesList()`
 * @return {!Array<string>}
 */
proto.toit.model.MetricsData.prototype.getNamesList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getNamesList()));
};


/**
 * repeated bytes names = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNamesList()`
 * @return {!Array<!Uint8Array>}
 */
proto.toit.model.MetricsData.prototype.getNamesList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getNamesList()));
};


/** @param {!(Array<!Uint8Array>|Array<string>)} value */
proto.toit.model.MetricsData.prototype.setNamesList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 */
proto.toit.model.MetricsData.prototype.addNames = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.toit.model.MetricsData.prototype.clearNamesList = function() {
  this.setNamesList([]);
};


/**
 * repeated GuageMetricData gauges = 2;
 * @return {!Array<!proto.toit.model.GuageMetricData>}
 */
proto.toit.model.MetricsData.prototype.getGaugesList = function() {
  return /** @type{!Array<!proto.toit.model.GuageMetricData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.toit.model.GuageMetricData, 2));
};


/** @param {!Array<!proto.toit.model.GuageMetricData>} value */
proto.toit.model.MetricsData.prototype.setGaugesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.toit.model.GuageMetricData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.toit.model.GuageMetricData}
 */
proto.toit.model.MetricsData.prototype.addGauges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.toit.model.GuageMetricData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.toit.model.MetricsData.prototype.clearGaugesList = function() {
  this.setGaugesList([]);
};


/**
 * repeated CounterMetricData counters = 3;
 * @return {!Array<!proto.toit.model.CounterMetricData>}
 */
proto.toit.model.MetricsData.prototype.getCountersList = function() {
  return /** @type{!Array<!proto.toit.model.CounterMetricData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.toit.model.CounterMetricData, 3));
};


/** @param {!Array<!proto.toit.model.CounterMetricData>} value */
proto.toit.model.MetricsData.prototype.setCountersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.toit.model.CounterMetricData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.toit.model.CounterMetricData}
 */
proto.toit.model.MetricsData.prototype.addCounters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.toit.model.CounterMetricData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.toit.model.MetricsData.prototype.clearCountersList = function() {
  this.setCountersList([]);
};


/**
 * repeated PlotMetricData plots = 4;
 * @return {!Array<!proto.toit.model.PlotMetricData>}
 */
proto.toit.model.MetricsData.prototype.getPlotsList = function() {
  return /** @type{!Array<!proto.toit.model.PlotMetricData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.toit.model.PlotMetricData, 4));
};


/** @param {!Array<!proto.toit.model.PlotMetricData>} value */
proto.toit.model.MetricsData.prototype.setPlotsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.toit.model.PlotMetricData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.toit.model.PlotMetricData}
 */
proto.toit.model.MetricsData.prototype.addPlots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.toit.model.PlotMetricData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.toit.model.MetricsData.prototype.clearPlotsList = function() {
  this.setPlotsList([]);
};


/**
 * repeated HistogramMetricData histograms = 5;
 * @return {!Array<!proto.toit.model.HistogramMetricData>}
 */
proto.toit.model.MetricsData.prototype.getHistogramsList = function() {
  return /** @type{!Array<!proto.toit.model.HistogramMetricData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.toit.model.HistogramMetricData, 5));
};


/** @param {!Array<!proto.toit.model.HistogramMetricData>} value */
proto.toit.model.MetricsData.prototype.setHistogramsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.toit.model.HistogramMetricData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.toit.model.HistogramMetricData}
 */
proto.toit.model.MetricsData.prototype.addHistograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.toit.model.HistogramMetricData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.toit.model.MetricsData.prototype.clearHistogramsList = function() {
  this.setHistogramsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.GuageMetricData.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.GuageMetricData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.GuageMetricData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.GuageMetricData.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    nameIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tagsMap: (f = msg.getTagsMap()) ? f.toObject(includeInstance, undefined) : [],
    level: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.GuageMetricData}
 */
proto.toit.model.GuageMetricData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.GuageMetricData;
  return proto.toit.model.GuageMetricData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.GuageMetricData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.GuageMetricData}
 */
proto.toit.model.GuageMetricData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNameIndex(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 4:
      var value = msg.getTagsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0);
         });
      break;
    case 5:
      var value = /** @type {!proto.toit.model.MetricsData.Level} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.GuageMetricData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.GuageMetricData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.GuageMetricData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.GuageMetricData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getNameIndex();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTagsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.toit.model.GuageMetricData.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.toit.model.GuageMetricData.prototype.setValue = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional uint32 name_index = 2;
 * @return {number}
 */
proto.toit.model.GuageMetricData.prototype.getNameIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.toit.model.GuageMetricData.prototype.setNameIndex = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.GuageMetricData.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.GuageMetricData.prototype.setCreated = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.GuageMetricData.prototype.clearCreated = function() {
  this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.GuageMetricData.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<uint32, uint32> tags = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.toit.model.GuageMetricData.prototype.getTagsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.toit.model.GuageMetricData.prototype.clearTagsMap = function() {
  this.getTagsMap().clear();
};


/**
 * optional MetricsData.Level level = 5;
 * @return {!proto.toit.model.MetricsData.Level}
 */
proto.toit.model.GuageMetricData.prototype.getLevel = function() {
  return /** @type {!proto.toit.model.MetricsData.Level} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.toit.model.MetricsData.Level} value */
proto.toit.model.GuageMetricData.prototype.setLevel = function(value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.CounterMetricData.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.CounterMetricData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.CounterMetricData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.CounterMetricData.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameIndex: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mean: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    stdev: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    tagsMap: (f = msg.getTagsMap()) ? f.toObject(includeInstance, undefined) : [],
    level: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.CounterMetricData}
 */
proto.toit.model.CounterMetricData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.CounterMetricData;
  return proto.toit.model.CounterMetricData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.CounterMetricData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.CounterMetricData}
 */
proto.toit.model.CounterMetricData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNameIndex(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMean(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStdev(value);
      break;
    case 5:
      var value = msg.getTagsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0);
         });
      break;
    case 6:
      var value = /** @type {!proto.toit.model.MetricsData.Level} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.CounterMetricData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.CounterMetricData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.CounterMetricData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.CounterMetricData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameIndex();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMean();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getStdev();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTagsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional uint32 name_index = 1;
 * @return {number}
 */
proto.toit.model.CounterMetricData.prototype.getNameIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.toit.model.CounterMetricData.prototype.setNameIndex = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.toit.model.CounterMetricData.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.toit.model.CounterMetricData.prototype.setCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double mean = 3;
 * @return {number}
 */
proto.toit.model.CounterMetricData.prototype.getMean = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.toit.model.CounterMetricData.prototype.setMean = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double stdev = 4;
 * @return {number}
 */
proto.toit.model.CounterMetricData.prototype.getStdev = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.toit.model.CounterMetricData.prototype.setStdev = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * map<uint32, uint32> tags = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.toit.model.CounterMetricData.prototype.getTagsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.toit.model.CounterMetricData.prototype.clearTagsMap = function() {
  this.getTagsMap().clear();
};


/**
 * optional MetricsData.Level level = 6;
 * @return {!proto.toit.model.MetricsData.Level}
 */
proto.toit.model.CounterMetricData.prototype.getLevel = function() {
  return /** @type {!proto.toit.model.MetricsData.Level} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.toit.model.MetricsData.Level} value */
proto.toit.model.CounterMetricData.prototype.setLevel = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.PlotMetricData.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.PlotMetricData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.PlotMetricData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.PlotMetricData.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    nameIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tagsMap: (f = msg.getTagsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.PlotMetricData}
 */
proto.toit.model.PlotMetricData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.PlotMetricData;
  return proto.toit.model.PlotMetricData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.PlotMetricData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.PlotMetricData}
 */
proto.toit.model.PlotMetricData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNameIndex(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 4:
      var value = msg.getTagsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.PlotMetricData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.PlotMetricData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.PlotMetricData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.PlotMetricData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getNameIndex();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTagsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.toit.model.PlotMetricData.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.toit.model.PlotMetricData.prototype.setValue = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional uint32 name_index = 2;
 * @return {number}
 */
proto.toit.model.PlotMetricData.prototype.getNameIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.toit.model.PlotMetricData.prototype.setNameIndex = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.PlotMetricData.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.PlotMetricData.prototype.setCreated = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.PlotMetricData.prototype.clearCreated = function() {
  this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.PlotMetricData.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<uint32, uint32> tags = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.toit.model.PlotMetricData.prototype.getTagsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.toit.model.PlotMetricData.prototype.clearTagsMap = function() {
  this.getTagsMap().clear();
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.HistogramMetricData.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.HistogramMetricData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.HistogramMetricData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.HistogramMetricData.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameIndex: jspb.Message.getFieldWithDefault(msg, 1, 0),
    valuesMap: (f = msg.getValuesMap()) ? f.toObject(includeInstance, undefined) : [],
    tagsMap: (f = msg.getTagsMap()) ? f.toObject(includeInstance, undefined) : [],
    level: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.HistogramMetricData}
 */
proto.toit.model.HistogramMetricData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.HistogramMetricData;
  return proto.toit.model.HistogramMetricData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.HistogramMetricData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.HistogramMetricData}
 */
proto.toit.model.HistogramMetricData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNameIndex(value);
      break;
    case 2:
      var value = msg.getValuesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readDouble, null, 0);
         });
      break;
    case 3:
      var value = msg.getTagsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0);
         });
      break;
    case 4:
      var value = /** @type {!proto.toit.model.MetricsData.Level} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.HistogramMetricData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.HistogramMetricData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.HistogramMetricData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.HistogramMetricData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameIndex();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getValuesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeDouble);
  }
  f = message.getTagsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional uint32 name_index = 1;
 * @return {number}
 */
proto.toit.model.HistogramMetricData.prototype.getNameIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.toit.model.HistogramMetricData.prototype.setNameIndex = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<uint32, double> values = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.toit.model.HistogramMetricData.prototype.getValuesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.toit.model.HistogramMetricData.prototype.clearValuesMap = function() {
  this.getValuesMap().clear();
};


/**
 * map<uint32, uint32> tags = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.toit.model.HistogramMetricData.prototype.getTagsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.toit.model.HistogramMetricData.prototype.clearTagsMap = function() {
  this.getTagsMap().clear();
};


/**
 * optional MetricsData.Level level = 4;
 * @return {!proto.toit.model.MetricsData.Level}
 */
proto.toit.model.HistogramMetricData.prototype.getLevel = function() {
  return /** @type {!proto.toit.model.MetricsData.Level} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.toit.model.MetricsData.Level} value */
proto.toit.model.HistogramMetricData.prototype.setLevel = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.TopicData.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.TopicData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.TopicData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.TopicData.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.TopicData}
 */
proto.toit.model.TopicData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.TopicData;
  return proto.toit.model.TopicData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.TopicData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.TopicData}
 */
proto.toit.model.TopicData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.TopicData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.TopicData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.TopicData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.TopicData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string topic = 1;
 * @return {string}
 */
proto.toit.model.TopicData.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.model.TopicData.prototype.setTopic = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.TopicData.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.toit.model.TopicData.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.toit.model.TopicData.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.TopicData.prototype.setData = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.toit.model.LogData.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.LogData.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.LogData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.LogData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.LogData.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: msg.getData_asB64(),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    level: jspb.Message.getFieldWithDefault(msg, 4, 0),
    namesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    tagsMap: (f = msg.getTagsMap()) ? f.toObject(includeInstance, proto.google.protobuf.Value.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.LogData}
 */
proto.toit.model.LogData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.LogData;
  return proto.toit.model.LogData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.LogData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.LogData}
 */
proto.toit.model.LogData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.toit.model.LogData.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {!proto.toit.model.LogData.Level} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addNames(value);
      break;
    case 6:
      var value = msg.getTagsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.google.protobuf.Value.deserializeBinaryFromReader, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.LogData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.LogData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.LogData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.LogData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getTagsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.google.protobuf.Value.serializeBinaryToWriter);
  }
};


/**
 * @enum {number}
 */
proto.toit.model.LogData.Type = {
  UNKNOWN: 0,
  PRINT: 1,
  PROCESS_START: 2,
  PROCESS_STOP: 3,
  STACK_TRACE: 4,
  BOOT: 5,
  SHUTDOWN: 6
};

/**
 * @enum {number}
 */
proto.toit.model.LogData.Level = {
  PRINT_LEVEL: 0,
  DEBUG_LEVEL: 1,
  INFO_LEVEL: 2,
  WARN_LEVEL: 3,
  ERROR_LEVEL: 4,
  FATAL_LEVEL: 5
};

/**
 * optional Type type = 1;
 * @return {!proto.toit.model.LogData.Type}
 */
proto.toit.model.LogData.prototype.getType = function() {
  return /** @type {!proto.toit.model.LogData.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.toit.model.LogData.Type} value */
proto.toit.model.LogData.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.LogData.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.toit.model.LogData.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.toit.model.LogData.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.LogData.prototype.setData = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.toit.model.LogData.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.toit.model.LogData.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Level level = 4;
 * @return {!proto.toit.model.LogData.Level}
 */
proto.toit.model.LogData.prototype.getLevel = function() {
  return /** @type {!proto.toit.model.LogData.Level} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.toit.model.LogData.Level} value */
proto.toit.model.LogData.prototype.setLevel = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated string names = 5;
 * @return {!Array<string>}
 */
proto.toit.model.LogData.prototype.getNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/** @param {!Array<string>} value */
proto.toit.model.LogData.prototype.setNamesList = function(value) {
  jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.toit.model.LogData.prototype.addNames = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.toit.model.LogData.prototype.clearNamesList = function() {
  this.setNamesList([]);
};


/**
 * map<string, google.protobuf.Value> tags = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.Value>}
 */
proto.toit.model.LogData.prototype.getTagsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.Value>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.google.protobuf.Value));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.toit.model.LogData.prototype.clearTagsMap = function() {
  this.getTagsMap().clear();
};


goog.object.extend(exports, proto.toit.model);
