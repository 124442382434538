import {
  Button,
  createStyles,
  Grid,
  Link,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { match } from "react-router";
import { CONSOLE_URL, context } from "../App";
import { ReactComponent as Logo } from "../assets/images/toit-logo.svg";
import { black, toitGreen } from "../assets/theme/theme";
import { SnackBar } from "./SnackBar";

const styles = (theme: Theme) =>
  createStyles({
    descriptionContainer: {
      background: toitGreen,
    },
    container: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
      width: "100vw",
      height: "100vh",
    },
    logo: {
      padding: theme.spacing(2),
    },
    signUpContent: {
      width: "50%",
      maxWidth: "450px",
      [theme.breakpoints.only("xs")]: {
        width: "70%",
      },
    },
    leftContent: {
      paddingTop: "18vh",
      [theme.breakpoints.only("xs")]: {
        paddingTop: "6vh",
      },
    },
    rightContent: {
      paddingTop: "22vh",
      [theme.breakpoints.only("xs")]: {
        paddingTop: "6vh",
      },
    },
    initiateResetPasswordForm: {
      width: "50%",
      maxWidth: "450px",
      [theme.breakpoints.only("xs")]: {
        width: "calc(100% - 32px)",
      },
    },
    topButton: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    inputLabel: {
      color: black,
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    checkmarkText: {
      marginLeft: theme.spacing(1),
    },
    perkList: {
      marginTop: theme.spacing(3),
    },
    avatar: {
      backgroundColor: "transparent",
    },
    mobileSpacer: {
      paddingBottom: theme.spacing(4),
    },
    heading: {
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  });

interface State {
  submitted: boolean;
  error: string | null;
  email: string;
}

interface ResetPasswordParams {
  email: string;
}

interface InitiateResetPasswordProps extends WithStyles<typeof styles> {
  match: match<ResetPasswordParams>;
}

class InitiateResetPasswordView extends React.Component<InitiateResetPasswordProps, State> {
  public readonly state: State = {
    email: "",
    submitted: false,
    error: null,
  };

  componentDidMount() {
    this.setState({
      email: this.props.match.params.email || "",
      submitted: false,
    });
  }

  handleTextChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        [name]: event.target.value,
      };
    });
  };

  handleInitiateResetPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    context
      .getServiceClient()
      .initiateResetPassword(this.state.email)
      .then((result) => {
        this.setState({
          submitted: true,
          error: null,
        });
      })
      .catch((e) => {
        // Don't display DB information for the user
        if (e instanceof Object && "message" in e) {
          const err = e as { message: string };

          // typeof err.message === "string" &&
          // !err.message.includes("User not found")
          this.setState({
            submitted: false,
            error: err.message,
          });
        }
      });
  };

  render() {
    const classes = this.props.classes;

    return (
      <Grid container className={classes.container}>
        <Helmet title="Reset password - Toit" />
        <Grid item xs={12}>
          <Grid xs={12} item>
            <Link href="https://toit.io">
              <Logo className={this.props.classes.logo} />
            </Link>
          </Grid>
          <Grid container item xs={12} justify="center" className={classes.leftContent}>
            <Grid container item justify="center" className={classes.heading}>
              <Typography variant="h1">Reset password</Typography>
            </Grid>
            <form
              noValidate
              onSubmit={this.handleInitiateResetPassword}
              className={this.props.classes.initiateResetPasswordForm}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={this.handleTextChange("email")}
                value={this.state.email}
                autoComplete="email"
                autoFocus
              />
              <Grid item xs={12}>
                <div className={classes.button}>
                  <Button type="submit" fullWidth variant="contained" color="primary">
                    Reset
                  </Button>
                </div>
              </Grid>
              <Grid container item xs={12} justify="center">
                <a href={CONSOLE_URL + "/login"}>Go to sign in</a>
              </Grid>
              <div>
                <SnackBar
                  open={this.state.submitted || false}
                  onClose={() => this.setState({ submitted: false })}
                  type="info"
                >
                  An email has been sent to {this.state.email} requesting a password reset
                </SnackBar>
              </div>
            </form>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(InitiateResetPasswordView);
