// package: toit.api
// file: toit/api/user.proto

var toit_api_user_pb = require("../../toit/api/user_pb");
var toit_api_organization_pb = require("../../toit/api/organization_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var User = (function () {
  function User() {}
  User.serviceName = "toit.api.User";
  return User;
}());

User.SetPassword = {
  methodName: "SetPassword",
  service: User,
  requestStream: false,
  responseStream: false,
  requestType: toit_api_user_pb.SetPasswordRequest,
  responseType: toit_api_user_pb.SetPasswordResponse
};

User.InitiateResetPassword = {
  methodName: "InitiateResetPassword",
  service: User,
  requestStream: false,
  responseStream: false,
  requestType: toit_api_user_pb.InitiateResetPasswordRequest,
  responseType: toit_api_user_pb.InitiateResetPasswordResponse
};

User.ChangePasswordWithRPToken = {
  methodName: "ChangePasswordWithRPToken",
  service: User,
  requestStream: false,
  responseStream: false,
  requestType: toit_api_user_pb.ChangePasswordWithRPTokenRequest,
  responseType: toit_api_user_pb.ChangePasswordWithRPTokenResponse
};

User.GetCurrentUser = {
  methodName: "GetCurrentUser",
  service: User,
  requestStream: false,
  responseStream: false,
  requestType: toit_api_user_pb.GetCurrentUserRequest,
  responseType: toit_api_user_pb.GetCurrentUserResponse
};

User.ListOrganizations = {
  methodName: "ListOrganizations",
  service: User,
  requestStream: false,
  responseStream: false,
  requestType: toit_api_user_pb.ListOrganizationsRequest,
  responseType: toit_api_user_pb.ListOrganizationsResponse
};

User.GetOrganization = {
  methodName: "GetOrganization",
  service: User,
  requestStream: false,
  responseStream: false,
  requestType: toit_api_user_pb.GetOrganizationRequest,
  responseType: toit_api_user_pb.GetOrganizationResponse
};

User.ListUsers = {
  methodName: "ListUsers",
  service: User,
  requestStream: false,
  responseStream: false,
  requestType: toit_api_organization_pb.ListUsersRequest,
  responseType: toit_api_organization_pb.ListUsersResponse
};

User.CreateUser = {
  methodName: "CreateUser",
  service: User,
  requestStream: false,
  responseStream: false,
  requestType: toit_api_organization_pb.CreateUserRequest,
  responseType: toit_api_organization_pb.CreateUserResponse
};

User.CreatePaymentSubscription = {
  methodName: "CreatePaymentSubscription",
  service: User,
  requestStream: false,
  responseStream: false,
  requestType: toit_api_user_pb.CreatePaymentSubscriptionRequest,
  responseType: toit_api_user_pb.CreatePaymentSubscriptionResponse
};

User.ListPaymentInvoices = {
  methodName: "ListPaymentInvoices",
  service: User,
  requestStream: false,
  responseStream: true,
  requestType: toit_api_user_pb.ListPaymentInvoicesRequest,
  responseType: toit_api_user_pb.ListPaymentInvoicesResponse
};

User.CancelPaymentSubscription = {
  methodName: "CancelPaymentSubscription",
  service: User,
  requestStream: false,
  responseStream: false,
  requestType: toit_api_user_pb.CancelPaymentSubscriptionRequest,
  responseType: toit_api_user_pb.CancelPaymentSubscriptionResponse
};

User.UpdateUser = {
  methodName: "UpdateUser",
  service: User,
  requestStream: false,
  responseStream: false,
  requestType: toit_api_user_pb.UpdateUserRequest,
  responseType: toit_api_user_pb.UpdateUserResponse
};

exports.User = User;

function UserClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

UserClient.prototype.setPassword = function setPassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(User.SetPassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserClient.prototype.initiateResetPassword = function initiateResetPassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(User.InitiateResetPassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserClient.prototype.changePasswordWithRPToken = function changePasswordWithRPToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(User.ChangePasswordWithRPToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserClient.prototype.getCurrentUser = function getCurrentUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(User.GetCurrentUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserClient.prototype.listOrganizations = function listOrganizations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(User.ListOrganizations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserClient.prototype.getOrganization = function getOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(User.GetOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserClient.prototype.listUsers = function listUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(User.ListUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserClient.prototype.createUser = function createUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(User.CreateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserClient.prototype.createPaymentSubscription = function createPaymentSubscription(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(User.CreatePaymentSubscription, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserClient.prototype.listPaymentInvoices = function listPaymentInvoices(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(User.ListPaymentInvoices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UserClient.prototype.cancelPaymentSubscription = function cancelPaymentSubscription(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(User.CancelPaymentSubscription, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserClient.prototype.updateUser = function updateUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(User.UpdateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.UserClient = UserClient;

