import { createMuiTheme } from "@material-ui/core";

export const white = "#ffffff";
export const backgroundGray = "#fbfbfb";
export const dividerGrey = "#d9d9d9";
export const black = "#000000";
export const toitGreen = "#BDDCD8";

export const selected = "#272D3D";
export const lightGray = "#C4C4C4";

// Note! If the theme colors change, the color placeholder in ../pkg/emailprovider/ must be manually updated

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: black,
      light: white,
      dark: black,
    },
    secondary: {
      main: white,
      light: white,
      dark: black,
    },
  },
  typography: {
    fontFamily: ["Roboto"].join(","),
    body1: {
      fontSize: "0.85rem",
    },
    body2: {
      fontSize: "0.80rem",
    },
    h1: {
      fontFamily: ["ClashDisplay-Medium"].join(","),
      fontSize: "2.5rem",
    },
    h2: {
      fontFamily: ["ClashDisplay-Medium"].join(","),
      fontSize: "1.5rem",
    },
    h3: {
      fontFamily: ["Roboto"].join(","),
    },
    h4: {
      fontFamily: ["Roboto"].join(","),
    },
    h5: {
      fontFamily: ["Roboto"].join(","),
      fontSize: "1.25rem",
    },
    h6: {
      fontFamily: ["Roboto"].join(","),
      padding: 0.5,
      fontSize: "1rem",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        boxShadow: "none",
      },
      outlined: {
        borderColor: black,
        boxSizing: "border-box",
        color: black,
        "&:hover": {
          borderColor: black,
        },
        backgroundColor: white,
      },
      contained: {
        backgroundColor: black,
        color: white,
        "&:hover": {
          backgroundColor: black,
          boxShadow: "none",
        },
        "&:disabled": {
          backgroundColor: lightGray,
        },
        boxShadow: "none",
      },
    },
  },
});

export default theme;
