import {
  Button,
  createStyles,
  Grid,
  Link,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink, match } from "react-router-dom";
import { CONSOLE_URL, context } from "../App";
import { ReactComponent as Logo } from "../assets/images/toit-logo.svg";
import { SnackBar } from "./SnackBar";

// const signupURL = "https://toit.io/#sign-up";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
      width: "100vw",
      height: "100vh",
    },
    logo: {
      padding: theme.spacing(2),
    },
    content: {
      paddingTop: "18vh",
      [theme.breakpoints.only("xs")]: {
        paddingTop: "6vh",
      },
    },
    changePasswordForm: {
      width: "50%",
      maxWidth: "450px",
      [theme.breakpoints.only("xs")]: {
        width: "calc(100% - 32px)",
      },
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    heading: {
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    link: {
      textDecorationLine: "underline",
    },
  });

interface State {
  error: boolean;
  errMsg: string | null;
  newpassword: string;
  confirmpassword: string;
  success: boolean;
}

interface ChangePasswordParams {
  token: string;
}

interface ChangePasswordProps extends WithStyles<typeof styles> {
  match: match<ChangePasswordParams>;
  history: History;
}

class ChangePasswordView extends React.Component<ChangePasswordProps, State> {
  state = {
    error: false,
    errMsg: "",
    newpassword: "",
    confirmpassword: "",
    success: false,
  };

  handleTextChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        error: false,
        errMsg: null,
        [name]: event.target.value,
      };
    });
  };

  handleChangePassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (this.state.confirmpassword !== this.state.newpassword) {
      this.setState({
        error: true,
        errMsg: "Passwords do not match",
      });
    } else {
      context
        .getServiceClient()
        .changePasswordWithRPToken(this.state.newpassword, this.props.match.params.token)
        .then((result) => {
          this.setState({
            error: false,
            errMsg: null,
            confirmpassword: "",
            newpassword: "",
            success: true,
          });
          // route back to login page if password changed succeeded
          setTimeout(() => (window.location.href = CONSOLE_URL + "/login"), 3000);
        })
        .catch((e) => {
          this.setState({
            error: true,
            errMsg: "" + e,
          });
        });
      return;
    }
  };

  onCloseInfoSnackbar = () => {
    this.setState({ success: false });
  };

  componentDidMount() {
    this.setState({
      error: false,
      errMsg: null,
    });
  }

  render() {
    const state: State = this.state || {};

    const message: string = this.state.errMsg + "";
    const tokenExpired: boolean = this.state.errMsg ? message.indexOf("invalid reset password token") !== -1 : false;

    return (
      <Grid container className={this.props.classes.container}>
        <Helmet title="Change password - Toit" />
        <Grid item xs={12}>
          <Grid xs={12} item>
            <Link href="https://toit.io">
              <Logo className={this.props.classes.logo} />
            </Link>
          </Grid>
          <Grid container item xs={12} justify="center" className={this.props.classes.content}>
            <Grid container item justify="center" className={this.props.classes.heading}>
              <Typography variant="h1">Change password</Typography>
            </Grid>
            <form noValidate onSubmit={this.handleChangePassword} className={this.props.classes.changePasswordForm}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="newpassword"
                label="Enter new password"
                type="password"
                id="newpassword"
                onChange={this.handleTextChange("newpassword")}
                autoFocus
                autoComplete="new-password"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmpassword"
                label="Confirm password"
                type="password"
                id="confirmpassword"
                onChange={this.handleTextChange("confirmpassword")}
                autoComplete="new-password"
              />
              <Typography variant="body2" color="error" hidden={!state.error}>
                {state.errMsg + ".\n"}
              </Typography>
              <div className={this.props.classes.button}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!this.state.newpassword || !this.state.confirmpassword}
                >
                  Submit
                </Button>
              </div>
            </form>
            <Link
              className={this.props.classes.link}
              hidden={!tokenExpired}
              component={RouterLink}
              to={"/resetpassword"}
            >
              Reset Password
            </Link>
            <SnackBar open={this.state.success} type="info" onClose={() => this.onCloseInfoSnackbar()}>
              Password successfully changed. Now redirecting to the login page.
            </SnackBar>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ChangePasswordView);
