import ServiceClient from "./service-client";

export class ServiceContext {
  private serviceClient: ServiceClient;

  constructor(serviceClient: ServiceClient) {
    this.serviceClient = serviceClient;
  }

  getServiceClient(): ServiceClient {
    return this.serviceClient;
  }
}
