/* eslint-disable */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.toit.model.APIKey', null, global);
goog.exportSymbol('proto.toit.model.Customer', null, global);
goog.exportSymbol('proto.toit.model.Customer.Type', null, global);
goog.exportSymbol('proto.toit.model.Organization', null, global);
goog.exportSymbol('proto.toit.model.PaymentDetails', null, global);
goog.exportSymbol('proto.toit.model.Tier', null, global);
goog.exportSymbol('proto.toit.model.Tier.Type', null, global);
goog.exportSymbol('proto.toit.model.User', null, global);
goog.exportSymbol('proto.toit.model.User.Settings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.Organization.displayName = 'proto.toit.model.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.PaymentDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.PaymentDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.PaymentDetails.displayName = 'proto.toit.model.PaymentDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.User.displayName = 'proto.toit.model.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.User.Settings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.User.Settings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.User.Settings.displayName = 'proto.toit.model.User.Settings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.APIKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.APIKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.APIKey.displayName = 'proto.toit.model.APIKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.Tier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.Tier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.Tier.displayName = 'proto.toit.model.Tier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.model.Customer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.model.Customer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.model.Customer.displayName = 'proto.toit.model.Customer';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    defaultSdk: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cvrNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    tier: jspb.Message.getFieldWithDefault(msg, 9, 0),
    paymentDetails: (f = msg.getPaymentDetails()) && proto.toit.model.PaymentDetails.toObject(includeInstance, f),
    addressLine: jspb.Message.getFieldWithDefault(msg, 11, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    vatNumber: jspb.Message.getFieldWithDefault(msg, 13, ""),
    vatCountryCode: jspb.Message.getFieldWithDefault(msg, 14, ""),
    customer: jspb.Message.getFieldWithDefault(msg, 15, 0),
    hwidQuota: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.Organization}
 */
proto.toit.model.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.Organization;
  return proto.toit.model.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.Organization}
 */
proto.toit.model.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultSdk(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCvrNumber(value);
      break;
    case 9:
      var value = /** @type {!proto.toit.model.Tier.Type} */ (reader.readEnum());
      msg.setTier(value);
      break;
    case 10:
      var value = new proto.toit.model.PaymentDetails;
      reader.readMessage(value,proto.toit.model.PaymentDetails.deserializeBinaryFromReader);
      msg.setPaymentDetails(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setVatNumber(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVatCountryCode(value);
      break;
    case 15:
      var value = /** @type {!proto.toit.model.Customer.Type} */ (reader.readEnum());
      msg.setCustomer(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setHwidQuota(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDefaultSdk();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCvrNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTier();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPaymentDetails();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.toit.model.PaymentDetails.serializeBinaryToWriter
    );
  }
  f = message.getAddressLine();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getVatNumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVatCountryCode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCustomer();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getHwidQuota();
  if (f !== 0) {
    writer.writeUint64(
      16,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.Organization.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.toit.model.Organization.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.toit.model.Organization.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.Organization.prototype.setId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.toit.model.Organization.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.toit.model.Organization.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string default_sdk = 3;
 * @return {string}
 */
proto.toit.model.Organization.prototype.getDefaultSdk = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.toit.model.Organization.prototype.setDefaultSdk = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.toit.model.Organization.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.toit.model.Organization.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.toit.model.Organization.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.toit.model.Organization.prototype.setCity = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.toit.model.Organization.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.toit.model.Organization.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string phone_number = 7;
 * @return {string}
 */
proto.toit.model.Organization.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.toit.model.Organization.prototype.setPhoneNumber = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string cvr_number = 8;
 * @return {string}
 */
proto.toit.model.Organization.prototype.getCvrNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.toit.model.Organization.prototype.setCvrNumber = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Tier.Type tier = 9;
 * @return {!proto.toit.model.Tier.Type}
 */
proto.toit.model.Organization.prototype.getTier = function() {
  return /** @type {!proto.toit.model.Tier.Type} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {!proto.toit.model.Tier.Type} value */
proto.toit.model.Organization.prototype.setTier = function(value) {
  jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional PaymentDetails payment_details = 10;
 * @return {?proto.toit.model.PaymentDetails}
 */
proto.toit.model.Organization.prototype.getPaymentDetails = function() {
  return /** @type{?proto.toit.model.PaymentDetails} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.PaymentDetails, 10));
};


/** @param {?proto.toit.model.PaymentDetails|undefined} value */
proto.toit.model.Organization.prototype.setPaymentDetails = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.Organization.prototype.clearPaymentDetails = function() {
  this.setPaymentDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.Organization.prototype.hasPaymentDetails = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string address_line = 11;
 * @return {string}
 */
proto.toit.model.Organization.prototype.getAddressLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.toit.model.Organization.prototype.setAddressLine = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string postal_code = 12;
 * @return {string}
 */
proto.toit.model.Organization.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.toit.model.Organization.prototype.setPostalCode = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string vat_number = 13;
 * @return {string}
 */
proto.toit.model.Organization.prototype.getVatNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.toit.model.Organization.prototype.setVatNumber = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string vat_country_code = 14;
 * @return {string}
 */
proto.toit.model.Organization.prototype.getVatCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.toit.model.Organization.prototype.setVatCountryCode = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional Customer.Type customer = 15;
 * @return {!proto.toit.model.Customer.Type}
 */
proto.toit.model.Organization.prototype.getCustomer = function() {
  return /** @type {!proto.toit.model.Customer.Type} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {!proto.toit.model.Customer.Type} value */
proto.toit.model.Organization.prototype.setCustomer = function(value) {
  jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional uint64 hwid_quota = 16;
 * @return {number}
 */
proto.toit.model.Organization.prototype.getHwidQuota = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.toit.model.Organization.prototype.setHwidQuota = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.PaymentDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.PaymentDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.PaymentDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.PaymentDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    last4: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expiry: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expiryMonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    expiryYear: jspb.Message.getFieldWithDefault(msg, 4, ""),
    brand: jspb.Message.getFieldWithDefault(msg, 5, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cvcCheck: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.PaymentDetails}
 */
proto.toit.model.PaymentDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.PaymentDetails;
  return proto.toit.model.PaymentDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.PaymentDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.PaymentDetails}
 */
proto.toit.model.PaymentDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLast4(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiry(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryMonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryYear(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrand(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCvcCheck(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.PaymentDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.PaymentDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.PaymentDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.PaymentDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLast4();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpiry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpiryMonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExpiryYear();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBrand();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCvcCheck();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string last4 = 1;
 * @return {string}
 */
proto.toit.model.PaymentDetails.prototype.getLast4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.model.PaymentDetails.prototype.setLast4 = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string expiry = 2;
 * @return {string}
 */
proto.toit.model.PaymentDetails.prototype.getExpiry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.toit.model.PaymentDetails.prototype.setExpiry = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string expiry_month = 3;
 * @return {string}
 */
proto.toit.model.PaymentDetails.prototype.getExpiryMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.toit.model.PaymentDetails.prototype.setExpiryMonth = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string expiry_year = 4;
 * @return {string}
 */
proto.toit.model.PaymentDetails.prototype.getExpiryYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.toit.model.PaymentDetails.prototype.setExpiryYear = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string brand = 5;
 * @return {string}
 */
proto.toit.model.PaymentDetails.prototype.getBrand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.toit.model.PaymentDetails.prototype.setBrand = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country_code = 6;
 * @return {string}
 */
proto.toit.model.PaymentDetails.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.toit.model.PaymentDetails.prototype.setCountryCode = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string cvc_check = 7;
 * @return {string}
 */
proto.toit.model.PaymentDetails.prototype.getCvcCheck = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.toit.model.PaymentDetails.prototype.setCvcCheck = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.User.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    organizationId: msg.getOrganizationId_asB64(),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    settings: (f = msg.getSettings()) && proto.toit.model.User.Settings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.User}
 */
proto.toit.model.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.User;
  return proto.toit.model.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.User}
 */
proto.toit.model.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = new proto.toit.model.User.Settings;
      reader.readMessage(value,proto.toit.model.User.Settings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getOrganizationId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.toit.model.User.Settings.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.User.Settings.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.User.Settings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.User.Settings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.User.Settings.toObject = function(includeInstance, msg) {
  var f, obj = {
    welcomeClosed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    newsletter: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.User.Settings}
 */
proto.toit.model.User.Settings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.User.Settings;
  return proto.toit.model.User.Settings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.User.Settings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.User.Settings}
 */
proto.toit.model.User.Settings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWelcomeClosed(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNewsletter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.User.Settings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.User.Settings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.User.Settings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.User.Settings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWelcomeClosed();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getNewsletter();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool welcome_closed = 1;
 * @return {boolean}
 */
proto.toit.model.User.Settings.prototype.getWelcomeClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.toit.model.User.Settings.prototype.setWelcomeClosed = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool newsletter = 2;
 * @return {boolean}
 */
proto.toit.model.User.Settings.prototype.getNewsletter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.toit.model.User.Settings.prototype.setNewsletter = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.User.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.toit.model.User.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.toit.model.User.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.User.prototype.setId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes organization_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.User.prototype.getOrganizationId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes organization_id = 2;
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {string}
 */
proto.toit.model.User.prototype.getOrganizationId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOrganizationId()));
};


/**
 * optional bytes organization_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {!Uint8Array}
 */
proto.toit.model.User.prototype.getOrganizationId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOrganizationId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.User.prototype.setOrganizationId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.toit.model.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.toit.model.User.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.toit.model.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.toit.model.User.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Settings settings = 5;
 * @return {?proto.toit.model.User.Settings}
 */
proto.toit.model.User.prototype.getSettings = function() {
  return /** @type{?proto.toit.model.User.Settings} */ (
    jspb.Message.getWrapperField(this, proto.toit.model.User.Settings, 5));
};


/** @param {?proto.toit.model.User.Settings|undefined} value */
proto.toit.model.User.prototype.setSettings = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.User.prototype.clearSettings = function() {
  this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.User.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.APIKey.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.APIKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.APIKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.APIKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    organizationId: msg.getOrganizationId_asB64(),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.APIKey}
 */
proto.toit.model.APIKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.APIKey;
  return proto.toit.model.APIKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.APIKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.APIKey}
 */
proto.toit.model.APIKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.APIKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.APIKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.APIKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.APIKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getOrganizationId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.APIKey.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.toit.model.APIKey.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.toit.model.APIKey.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.APIKey.prototype.setId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes organization_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.model.APIKey.prototype.getOrganizationId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes organization_id = 2;
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {string}
 */
proto.toit.model.APIKey.prototype.getOrganizationId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOrganizationId()));
};


/**
 * optional bytes organization_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {!Uint8Array}
 */
proto.toit.model.APIKey.prototype.getOrganizationId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOrganizationId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.model.APIKey.prototype.setOrganizationId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.toit.model.APIKey.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.toit.model.APIKey.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.model.APIKey.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.model.APIKey.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.model.APIKey.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.model.APIKey.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.Tier.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.Tier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.Tier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.Tier.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.Tier}
 */
proto.toit.model.Tier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.Tier;
  return proto.toit.model.Tier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.Tier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.Tier}
 */
proto.toit.model.Tier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.Tier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.Tier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.Tier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.Tier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.toit.model.Tier.Type = {
  UNKNOWN: 0,
  FREE: 1,
  PAID: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.model.Customer.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.model.Customer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.model.Customer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.Customer.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.model.Customer}
 */
proto.toit.model.Customer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.model.Customer;
  return proto.toit.model.Customer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.model.Customer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.model.Customer}
 */
proto.toit.model.Customer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.model.Customer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.model.Customer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.model.Customer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.model.Customer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.toit.model.Customer.Type = {
  UNKNOWN: 0,
  PERSON: 1,
  ORGANIZATION: 2
};

goog.object.extend(exports, proto.toit.model);
