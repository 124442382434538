import { Box, createStyles, Grid, Link, Theme, Typography, withStyles, WithStyles } from "@material-ui/core";
import queryString from "query-string";
import React from "react";
import { Helmet } from "react-helmet";
import { OAUTH_URL } from "../App";
import { ReactComponent as Logo } from "../assets/images/toit-logo.svg";
import theme, { black } from "../assets/theme/theme";

// const signupURL = "https://toit.io/#sign-up";

interface State {
  email: string;
  password: string;
  error?: string;
  success_uri?: string;
  client_id?: string;
  state?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
      width: "100vw",
      height: "100vh",
    },
    logo: {
      padding: theme.spacing(2),
    },
    content: {
      paddingTop: "18vh",
      [theme.breakpoints.only("xs")]: {
        paddingTop: "6vh",
      },
    },
    heading: {
      paddingBottom: theme.spacing(3),
    },
    errorText: {
      width: "50%",
      maxWidth: "600px",
      borderStyle: "solid",
      borderColor: black,
      borderWidth: "1px",
      borderRadius: 5,
      height: "50vh",
      minHeight: "300px",
    },
  });

type ErrorProps = WithStyles<typeof styles>;

class ErrorView extends React.Component<ErrorProps, State> {
  handleTextChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        [name]: event.target.value,
      };
    });
  };

  componentDidMount() {
    const values = queryString.parse(document.location.search);
    const err = this.getLast(values.error);
    this.setState({
      success_uri: this.getLast(values.success_uri),
      state: this.getLast(values.state),
      client_id: this.getLast(values.client_id),
      error:
        err === "login_failed"
          ? "Username and password did not match"
          : err === "invalid_success_uri"
          ? "Malformed OAuth2 login request"
          : err === "internal_error"
          ? "Internal error, try again"
          : undefined,
    });

    console.log(
      "Google Oauth2 link",
      OAUTH_URL + "/oauth/login/google?success_uri=" + encodeURIComponent(this.getLast(values.success_uri) || "")
    );
  }

  getLast = (v: string | string[] | null) => {
    if (v === null || v === undefined) {
      return undefined;
    }
    if (typeof v == "string") {
      return v;
    }
    return v[v.length - 1];
  };

  render() {
    const state: State = this.state || {};
    return (
      <Grid container className={this.props.classes.container}>
        <Helmet title="Login error - Toit" />
        <Grid item xs={12}>
          <Grid xs={12} item>
            <Link href="https://toit.io">
              <Logo className={this.props.classes.logo} />
            </Link>
          </Grid>
          <Grid container item xs={12} justify="center" className={this.props.classes.content}>
            <Grid container item justify="center" className={this.props.classes.heading}>
              <Typography variant="h1">You ran into an Error!</Typography>
            </Grid>
            <Grid container item className={this.props.classes.errorText}>
              <Box padding={theme.spacing(0.4)}>
                <Typography>{state.error || "No error message found"}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ErrorView);
