import { grpc } from "@improbable-eng/grpc-web";
import { AuthClient } from "../../proto/toit/api/auth_pb_service";
import { ChangePasswordWithRPTokenRequest, InitiateResetPasswordRequest } from "../../proto/toit/api/user_pb";
import { UserClient } from "../../proto/toit/api/user_pb_service";
import ServiceClient from "../service-client";

export default class ProtoServiceClient implements ServiceClient {
  private userClient: UserClient;
  private authClient: AuthClient;

  constructor(url: string) {
    const transport = grpc.CrossBrowserHttpTransport({ withCredentials: true });
    this.userClient = new UserClient(url, { transport: transport });
    this.authClient = new AuthClient(url, { transport: transport });
  }

  changePasswordWithRPToken(password: string, token: string): Promise<void> {
    const req = new ChangePasswordWithRPTokenRequest();
    req.setNewPassword(password);
    req.setToken(token);

    return new Promise<void>((resolve, reject) => {
      this.userClient.changePasswordWithRPToken(req, (err, resp) => {
        if (resp) {
          resolve();
        } else {
          reject(err || "No error or response given");
        }
      });
    });
  }

  initiateResetPassword(email: string): Promise<void> {
    const req = new InitiateResetPasswordRequest();
    req.setEmail(email);

    return new Promise<void>((resolve, reject) => {
      this.userClient.initiateResetPassword(req, (err, resp) => {
        if (resp) {
          resolve();
        } else {
          reject(err || "No error or response given");
        }
      });
    });
  }
}
