/* eslint-disable */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var toit_model_device_pb = require('../../toit/model/device_pb.js');
goog.object.extend(proto, toit_model_device_pb);
var toit_model_data_pb = require('../../toit/model/data_pb.js');
goog.object.extend(proto, toit_model_data_pb);
var toit_model_pubsub_message_pb = require('../../toit/model/pubsub/message_pb.js');
goog.object.extend(proto, toit_model_pubsub_message_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.toit.api.ClaimHardwareIdentityRequest', null, global);
goog.exportSymbol('proto.toit.api.ClaimHardwareIdentityResponse', null, global);
goog.exportSymbol('proto.toit.api.ClaimRequest', null, global);
goog.exportSymbol('proto.toit.api.ClaimResponse', null, global);
goog.exportSymbol('proto.toit.api.GetActiveDeviceIDRequest', null, global);
goog.exportSymbol('proto.toit.api.GetActiveDeviceIDResponse', null, global);
goog.exportSymbol('proto.toit.api.HardwareEvent', null, global);
goog.exportSymbol('proto.toit.api.HardwareEvent.Type', null, global);
goog.exportSymbol('proto.toit.api.HardwareEventsRequest', null, global);
goog.exportSymbol('proto.toit.api.HardwareEventsResponse', null, global);
goog.exportSymbol('proto.toit.api.ReplaceRequest', null, global);
goog.exportSymbol('proto.toit.api.ReplaceResponse', null, global);
goog.exportSymbol('proto.toit.api.SetHardwareIdentityInfoRequest', null, global);
goog.exportSymbol('proto.toit.api.SetHardwareIdentityInfoResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.ClaimRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.api.ClaimRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.ClaimRequest.displayName = 'proto.toit.api.ClaimRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.ClaimResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.api.ClaimResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.ClaimResponse.displayName = 'proto.toit.api.ClaimResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.ReplaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.api.ReplaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.ReplaceRequest.displayName = 'proto.toit.api.ReplaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.ReplaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.api.ReplaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.ReplaceResponse.displayName = 'proto.toit.api.ReplaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.GetActiveDeviceIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.api.GetActiveDeviceIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.GetActiveDeviceIDRequest.displayName = 'proto.toit.api.GetActiveDeviceIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.GetActiveDeviceIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.api.GetActiveDeviceIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.GetActiveDeviceIDResponse.displayName = 'proto.toit.api.GetActiveDeviceIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.HardwareEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.toit.api.HardwareEvent.oneofGroups_);
};
goog.inherits(proto.toit.api.HardwareEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.HardwareEvent.displayName = 'proto.toit.api.HardwareEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.HardwareEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.toit.api.HardwareEventsRequest.oneofGroups_);
};
goog.inherits(proto.toit.api.HardwareEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.HardwareEventsRequest.displayName = 'proto.toit.api.HardwareEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.HardwareEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.api.HardwareEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.HardwareEventsResponse.displayName = 'proto.toit.api.HardwareEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.ClaimHardwareIdentityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.api.ClaimHardwareIdentityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.ClaimHardwareIdentityRequest.displayName = 'proto.toit.api.ClaimHardwareIdentityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.ClaimHardwareIdentityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.api.ClaimHardwareIdentityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.ClaimHardwareIdentityResponse.displayName = 'proto.toit.api.ClaimHardwareIdentityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.SetHardwareIdentityInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.api.SetHardwareIdentityInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.SetHardwareIdentityInfoRequest.displayName = 'proto.toit.api.SetHardwareIdentityInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.toit.api.SetHardwareIdentityInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.toit.api.SetHardwareIdentityInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.toit.api.SetHardwareIdentityInfoResponse.displayName = 'proto.toit.api.SetHardwareIdentityInfoResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.ClaimRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.ClaimRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.ClaimRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.ClaimRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareId: msg.getHardwareId_asB64(),
    initDeviceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationId: msg.getOrganizationId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.ClaimRequest}
 */
proto.toit.api.ClaimRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.ClaimRequest;
  return proto.toit.api.ClaimRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.ClaimRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.ClaimRequest}
 */
proto.toit.api.ClaimRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHardwareId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitDeviceName(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.ClaimRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.ClaimRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.ClaimRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.ClaimRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getInitDeviceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional bytes hardware_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.ClaimRequest.prototype.getHardwareId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes hardware_id = 1;
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {string}
 */
proto.toit.api.ClaimRequest.prototype.getHardwareId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHardwareId()));
};


/**
 * optional bytes hardware_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {!Uint8Array}
 */
proto.toit.api.ClaimRequest.prototype.getHardwareId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHardwareId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.ClaimRequest.prototype.setHardwareId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string init_device_name = 2;
 * @return {string}
 */
proto.toit.api.ClaimRequest.prototype.getInitDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.toit.api.ClaimRequest.prototype.setInitDeviceName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes organization_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.ClaimRequest.prototype.getOrganizationId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes organization_id = 3;
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {string}
 */
proto.toit.api.ClaimRequest.prototype.getOrganizationId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOrganizationId()));
};


/**
 * optional bytes organization_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {!Uint8Array}
 */
proto.toit.api.ClaimRequest.prototype.getOrganizationId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOrganizationId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.ClaimRequest.prototype.setOrganizationId = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.ClaimResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.ClaimResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.ClaimResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.ClaimResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareInfo: (f = msg.getHardwareInfo()) && toit_model_device_pb.HardwareInfo.toObject(includeInstance, f),
    deviceId: msg.getDeviceId_asB64(),
    deviceName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    organizationId: msg.getOrganizationId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.ClaimResponse}
 */
proto.toit.api.ClaimResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.ClaimResponse;
  return proto.toit.api.ClaimResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.ClaimResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.ClaimResponse}
 */
proto.toit.api.ClaimResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new toit_model_device_pb.HardwareInfo;
      reader.readMessage(value,toit_model_device_pb.HardwareInfo.deserializeBinaryFromReader);
      msg.setHardwareInfo(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDeviceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.ClaimResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.ClaimResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.ClaimResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.ClaimResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      toit_model_device_pb.HardwareInfo.serializeBinaryToWriter
    );
  }
  f = message.getDeviceId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional toit.model.HardwareInfo hardware_info = 1;
 * @return {?proto.toit.model.HardwareInfo}
 */
proto.toit.api.ClaimResponse.prototype.getHardwareInfo = function() {
  return /** @type{?proto.toit.model.HardwareInfo} */ (
    jspb.Message.getWrapperField(this, toit_model_device_pb.HardwareInfo, 1));
};


/** @param {?proto.toit.model.HardwareInfo|undefined} value */
proto.toit.api.ClaimResponse.prototype.setHardwareInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.api.ClaimResponse.prototype.clearHardwareInfo = function() {
  this.setHardwareInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.api.ClaimResponse.prototype.hasHardwareInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes device_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.ClaimResponse.prototype.getDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes device_id = 2;
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {string}
 */
proto.toit.api.ClaimResponse.prototype.getDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDeviceId()));
};


/**
 * optional bytes device_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {!Uint8Array}
 */
proto.toit.api.ClaimResponse.prototype.getDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDeviceId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.ClaimResponse.prototype.setDeviceId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string device_name = 3;
 * @return {string}
 */
proto.toit.api.ClaimResponse.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.toit.api.ClaimResponse.prototype.setDeviceName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bytes organization_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.ClaimResponse.prototype.getOrganizationId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes organization_id = 4;
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {string}
 */
proto.toit.api.ClaimResponse.prototype.getOrganizationId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOrganizationId()));
};


/**
 * optional bytes organization_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {!Uint8Array}
 */
proto.toit.api.ClaimResponse.prototype.getOrganizationId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOrganizationId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.ClaimResponse.prototype.setOrganizationId = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.ReplaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.ReplaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.ReplaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.ReplaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    newHardwareId: msg.getNewHardwareId_asB64(),
    currentHardwareId: msg.getCurrentHardwareId_asB64(),
    deviceId: msg.getDeviceId_asB64(),
    organizationId: msg.getOrganizationId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.ReplaceRequest}
 */
proto.toit.api.ReplaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.ReplaceRequest;
  return proto.toit.api.ReplaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.ReplaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.ReplaceRequest}
 */
proto.toit.api.ReplaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNewHardwareId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCurrentHardwareId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDeviceId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.ReplaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.ReplaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.ReplaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.ReplaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewHardwareId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getCurrentHardwareId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getDeviceId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getOrganizationId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional bytes new_hardware_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.ReplaceRequest.prototype.getNewHardwareId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes new_hardware_id = 1;
 * This is a type-conversion wrapper around `getNewHardwareId()`
 * @return {string}
 */
proto.toit.api.ReplaceRequest.prototype.getNewHardwareId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNewHardwareId()));
};


/**
 * optional bytes new_hardware_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNewHardwareId()`
 * @return {!Uint8Array}
 */
proto.toit.api.ReplaceRequest.prototype.getNewHardwareId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNewHardwareId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.ReplaceRequest.prototype.setNewHardwareId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes current_hardware_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.ReplaceRequest.prototype.getCurrentHardwareId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes current_hardware_id = 2;
 * This is a type-conversion wrapper around `getCurrentHardwareId()`
 * @return {string}
 */
proto.toit.api.ReplaceRequest.prototype.getCurrentHardwareId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCurrentHardwareId()));
};


/**
 * optional bytes current_hardware_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCurrentHardwareId()`
 * @return {!Uint8Array}
 */
proto.toit.api.ReplaceRequest.prototype.getCurrentHardwareId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCurrentHardwareId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.ReplaceRequest.prototype.setCurrentHardwareId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes device_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.ReplaceRequest.prototype.getDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes device_id = 3;
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {string}
 */
proto.toit.api.ReplaceRequest.prototype.getDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDeviceId()));
};


/**
 * optional bytes device_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {!Uint8Array}
 */
proto.toit.api.ReplaceRequest.prototype.getDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDeviceId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.ReplaceRequest.prototype.setDeviceId = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes organization_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.ReplaceRequest.prototype.getOrganizationId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes organization_id = 4;
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {string}
 */
proto.toit.api.ReplaceRequest.prototype.getOrganizationId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOrganizationId()));
};


/**
 * optional bytes organization_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {!Uint8Array}
 */
proto.toit.api.ReplaceRequest.prototype.getOrganizationId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOrganizationId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.ReplaceRequest.prototype.setOrganizationId = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.ReplaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.ReplaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.ReplaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.ReplaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareInfo: (f = msg.getHardwareInfo()) && toit_model_device_pb.HardwareInfo.toObject(includeInstance, f),
    organizationId: msg.getOrganizationId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.ReplaceResponse}
 */
proto.toit.api.ReplaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.ReplaceResponse;
  return proto.toit.api.ReplaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.ReplaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.ReplaceResponse}
 */
proto.toit.api.ReplaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new toit_model_device_pb.HardwareInfo;
      reader.readMessage(value,toit_model_device_pb.HardwareInfo.deserializeBinaryFromReader);
      msg.setHardwareInfo(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.ReplaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.ReplaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.ReplaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.ReplaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      toit_model_device_pb.HardwareInfo.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional toit.model.HardwareInfo hardware_info = 1;
 * @return {?proto.toit.model.HardwareInfo}
 */
proto.toit.api.ReplaceResponse.prototype.getHardwareInfo = function() {
  return /** @type{?proto.toit.model.HardwareInfo} */ (
    jspb.Message.getWrapperField(this, toit_model_device_pb.HardwareInfo, 1));
};


/** @param {?proto.toit.model.HardwareInfo|undefined} value */
proto.toit.api.ReplaceResponse.prototype.setHardwareInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.api.ReplaceResponse.prototype.clearHardwareInfo = function() {
  this.setHardwareInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.api.ReplaceResponse.prototype.hasHardwareInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes organization_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.ReplaceResponse.prototype.getOrganizationId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes organization_id = 2;
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {string}
 */
proto.toit.api.ReplaceResponse.prototype.getOrganizationId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOrganizationId()));
};


/**
 * optional bytes organization_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {!Uint8Array}
 */
proto.toit.api.ReplaceResponse.prototype.getOrganizationId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOrganizationId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.ReplaceResponse.prototype.setOrganizationId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.GetActiveDeviceIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.GetActiveDeviceIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.GetActiveDeviceIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.GetActiveDeviceIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareId: msg.getHardwareId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.GetActiveDeviceIDRequest}
 */
proto.toit.api.GetActiveDeviceIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.GetActiveDeviceIDRequest;
  return proto.toit.api.GetActiveDeviceIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.GetActiveDeviceIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.GetActiveDeviceIDRequest}
 */
proto.toit.api.GetActiveDeviceIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHardwareId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.GetActiveDeviceIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.GetActiveDeviceIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.GetActiveDeviceIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.GetActiveDeviceIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes hardware_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.GetActiveDeviceIDRequest.prototype.getHardwareId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes hardware_id = 1;
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {string}
 */
proto.toit.api.GetActiveDeviceIDRequest.prototype.getHardwareId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHardwareId()));
};


/**
 * optional bytes hardware_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {!Uint8Array}
 */
proto.toit.api.GetActiveDeviceIDRequest.prototype.getHardwareId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHardwareId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.GetActiveDeviceIDRequest.prototype.setHardwareId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.GetActiveDeviceIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.GetActiveDeviceIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.GetActiveDeviceIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.GetActiveDeviceIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: msg.getDeviceId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.GetActiveDeviceIDResponse}
 */
proto.toit.api.GetActiveDeviceIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.GetActiveDeviceIDResponse;
  return proto.toit.api.GetActiveDeviceIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.GetActiveDeviceIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.GetActiveDeviceIDResponse}
 */
proto.toit.api.GetActiveDeviceIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.GetActiveDeviceIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.GetActiveDeviceIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.GetActiveDeviceIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.GetActiveDeviceIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes device_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.GetActiveDeviceIDResponse.prototype.getDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes device_id = 1;
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {string}
 */
proto.toit.api.GetActiveDeviceIDResponse.prototype.getDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDeviceId()));
};


/**
 * optional bytes device_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {!Uint8Array}
 */
proto.toit.api.GetActiveDeviceIDResponse.prototype.getDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDeviceId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.GetActiveDeviceIDResponse.prototype.setDeviceId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.toit.api.HardwareEvent.oneofGroups_ = [[9,10,11]];

/**
 * @enum {number}
 */
proto.toit.api.HardwareEvent.DataCase = {
  DATA_NOT_SET: 0,
  LOG: 9,
  METRICS: 10,
  PUBSUB: 11
};

/**
 * @return {proto.toit.api.HardwareEvent.DataCase}
 */
proto.toit.api.HardwareEvent.prototype.getDataCase = function() {
  return /** @type {proto.toit.api.HardwareEvent.DataCase} */(jspb.Message.computeOneofCase(this, proto.toit.api.HardwareEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.HardwareEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.HardwareEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.HardwareEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.HardwareEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareId: msg.getHardwareId_asB64(),
    eventId: msg.getEventId_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    jobId: msg.getJobId_asB64(),
    received: (f = msg.getReceived()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deviceId: msg.getDeviceId_asB64(),
    organizationId: msg.getOrganizationId_asB64(),
    log: (f = msg.getLog()) && toit_model_data_pb.LogData.toObject(includeInstance, f),
    metrics: (f = msg.getMetrics()) && toit_model_data_pb.MetricsData.toObject(includeInstance, f),
    pubsub: (f = msg.getPubsub()) && toit_model_pubsub_message_pb.Message.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.HardwareEvent}
 */
proto.toit.api.HardwareEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.HardwareEvent;
  return proto.toit.api.HardwareEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.HardwareEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.HardwareEvent}
 */
proto.toit.api.HardwareEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHardwareId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEventId(value);
      break;
    case 3:
      var value = /** @type {!proto.toit.api.HardwareEvent.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setJobId(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReceived(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDeviceId(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOrganizationId(value);
      break;
    case 9:
      var value = new toit_model_data_pb.LogData;
      reader.readMessage(value,toit_model_data_pb.LogData.deserializeBinaryFromReader);
      msg.setLog(value);
      break;
    case 10:
      var value = new toit_model_data_pb.MetricsData;
      reader.readMessage(value,toit_model_data_pb.MetricsData.deserializeBinaryFromReader);
      msg.setMetrics(value);
      break;
    case 11:
      var value = new toit_model_pubsub_message_pb.Message;
      reader.readMessage(value,toit_model_pubsub_message_pb.Message.deserializeBinaryFromReader);
      msg.setPubsub(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.HardwareEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.HardwareEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.HardwareEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.HardwareEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getEventId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getJobId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getReceived();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeviceId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getOrganizationId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getLog();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      toit_model_data_pb.LogData.serializeBinaryToWriter
    );
  }
  f = message.getMetrics();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      toit_model_data_pb.MetricsData.serializeBinaryToWriter
    );
  }
  f = message.getPubsub();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      toit_model_pubsub_message_pb.Message.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.toit.api.HardwareEvent.Type = {
  UNKNOWN: 0,
  LOGS: 1,
  METRICS: 2,
  PUBSUB: 3
};

/**
 * optional bytes hardware_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.HardwareEvent.prototype.getHardwareId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes hardware_id = 1;
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {string}
 */
proto.toit.api.HardwareEvent.prototype.getHardwareId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHardwareId()));
};


/**
 * optional bytes hardware_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {!Uint8Array}
 */
proto.toit.api.HardwareEvent.prototype.getHardwareId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHardwareId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.HardwareEvent.prototype.setHardwareId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes event_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.HardwareEvent.prototype.getEventId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes event_id = 2;
 * This is a type-conversion wrapper around `getEventId()`
 * @return {string}
 */
proto.toit.api.HardwareEvent.prototype.getEventId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEventId()));
};


/**
 * optional bytes event_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEventId()`
 * @return {!Uint8Array}
 */
proto.toit.api.HardwareEvent.prototype.getEventId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEventId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.HardwareEvent.prototype.setEventId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional Type type = 3;
 * @return {!proto.toit.api.HardwareEvent.Type}
 */
proto.toit.api.HardwareEvent.prototype.getType = function() {
  return /** @type {!proto.toit.api.HardwareEvent.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.toit.api.HardwareEvent.Type} value */
proto.toit.api.HardwareEvent.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bytes job_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.HardwareEvent.prototype.getJobId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes job_id = 4;
 * This is a type-conversion wrapper around `getJobId()`
 * @return {string}
 */
proto.toit.api.HardwareEvent.prototype.getJobId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getJobId()));
};


/**
 * optional bytes job_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getJobId()`
 * @return {!Uint8Array}
 */
proto.toit.api.HardwareEvent.prototype.getJobId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getJobId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.HardwareEvent.prototype.setJobId = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp received = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.api.HardwareEvent.prototype.getReceived = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.api.HardwareEvent.prototype.setReceived = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.api.HardwareEvent.prototype.clearReceived = function() {
  this.setReceived(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.api.HardwareEvent.prototype.hasReceived = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp created = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.api.HardwareEvent.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.api.HardwareEvent.prototype.setCreated = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.api.HardwareEvent.prototype.clearCreated = function() {
  this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.api.HardwareEvent.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bytes device_id = 7;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.HardwareEvent.prototype.getDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes device_id = 7;
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {string}
 */
proto.toit.api.HardwareEvent.prototype.getDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDeviceId()));
};


/**
 * optional bytes device_id = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {!Uint8Array}
 */
proto.toit.api.HardwareEvent.prototype.getDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDeviceId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.HardwareEvent.prototype.setDeviceId = function(value) {
  jspb.Message.setProto3BytesField(this, 7, value);
};


/**
 * optional bytes organization_id = 8;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.HardwareEvent.prototype.getOrganizationId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes organization_id = 8;
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {string}
 */
proto.toit.api.HardwareEvent.prototype.getOrganizationId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOrganizationId()));
};


/**
 * optional bytes organization_id = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOrganizationId()`
 * @return {!Uint8Array}
 */
proto.toit.api.HardwareEvent.prototype.getOrganizationId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOrganizationId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.HardwareEvent.prototype.setOrganizationId = function(value) {
  jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional toit.model.LogData log = 9;
 * @return {?proto.toit.model.LogData}
 */
proto.toit.api.HardwareEvent.prototype.getLog = function() {
  return /** @type{?proto.toit.model.LogData} */ (
    jspb.Message.getWrapperField(this, toit_model_data_pb.LogData, 9));
};


/** @param {?proto.toit.model.LogData|undefined} value */
proto.toit.api.HardwareEvent.prototype.setLog = function(value) {
  jspb.Message.setOneofWrapperField(this, 9, proto.toit.api.HardwareEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.api.HardwareEvent.prototype.clearLog = function() {
  this.setLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.api.HardwareEvent.prototype.hasLog = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional toit.model.MetricsData metrics = 10;
 * @return {?proto.toit.model.MetricsData}
 */
proto.toit.api.HardwareEvent.prototype.getMetrics = function() {
  return /** @type{?proto.toit.model.MetricsData} */ (
    jspb.Message.getWrapperField(this, toit_model_data_pb.MetricsData, 10));
};


/** @param {?proto.toit.model.MetricsData|undefined} value */
proto.toit.api.HardwareEvent.prototype.setMetrics = function(value) {
  jspb.Message.setOneofWrapperField(this, 10, proto.toit.api.HardwareEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.api.HardwareEvent.prototype.clearMetrics = function() {
  this.setMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.api.HardwareEvent.prototype.hasMetrics = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional toit.model.pubsub.Message pubsub = 11;
 * @return {?proto.toit.model.pubsub.Message}
 */
proto.toit.api.HardwareEvent.prototype.getPubsub = function() {
  return /** @type{?proto.toit.model.pubsub.Message} */ (
    jspb.Message.getWrapperField(this, toit_model_pubsub_message_pb.Message, 11));
};


/** @param {?proto.toit.model.pubsub.Message|undefined} value */
proto.toit.api.HardwareEvent.prototype.setPubsub = function(value) {
  jspb.Message.setOneofWrapperField(this, 11, proto.toit.api.HardwareEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.api.HardwareEvent.prototype.clearPubsub = function() {
  this.setPubsub(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.api.HardwareEvent.prototype.hasPubsub = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.toit.api.HardwareEventsRequest.oneofGroups_ = [[6,7]];

/**
 * @enum {number}
 */
proto.toit.api.HardwareEventsRequest.OffsetCase = {
  OFFSET_NOT_SET: 0,
  ID: 6,
  TS: 7
};

/**
 * @return {proto.toit.api.HardwareEventsRequest.OffsetCase}
 */
proto.toit.api.HardwareEventsRequest.prototype.getOffsetCase = function() {
  return /** @type {proto.toit.api.HardwareEventsRequest.OffsetCase} */(jspb.Message.computeOneofCase(this, proto.toit.api.HardwareEventsRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.HardwareEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.HardwareEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.HardwareEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.HardwareEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareId: msg.getHardwareId_asB64(),
    jobId: msg.getJobId_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    reverse: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    id: msg.getId_asB64(),
    ts: (f = msg.getTs()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.HardwareEventsRequest}
 */
proto.toit.api.HardwareEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.HardwareEventsRequest;
  return proto.toit.api.HardwareEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.HardwareEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.HardwareEventsRequest}
 */
proto.toit.api.HardwareEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHardwareId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setJobId(value);
      break;
    case 3:
      var value = /** @type {!proto.toit.api.HardwareEvent.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReverse(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.HardwareEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.HardwareEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.HardwareEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.HardwareEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getJobId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getReverse();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getTs();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes hardware_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.HardwareEventsRequest.prototype.getHardwareId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes hardware_id = 1;
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {string}
 */
proto.toit.api.HardwareEventsRequest.prototype.getHardwareId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHardwareId()));
};


/**
 * optional bytes hardware_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {!Uint8Array}
 */
proto.toit.api.HardwareEventsRequest.prototype.getHardwareId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHardwareId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.HardwareEventsRequest.prototype.setHardwareId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes job_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.HardwareEventsRequest.prototype.getJobId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes job_id = 2;
 * This is a type-conversion wrapper around `getJobId()`
 * @return {string}
 */
proto.toit.api.HardwareEventsRequest.prototype.getJobId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getJobId()));
};


/**
 * optional bytes job_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getJobId()`
 * @return {!Uint8Array}
 */
proto.toit.api.HardwareEventsRequest.prototype.getJobId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getJobId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.HardwareEventsRequest.prototype.setJobId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional HardwareEvent.Type type = 3;
 * @return {!proto.toit.api.HardwareEvent.Type}
 */
proto.toit.api.HardwareEventsRequest.prototype.getType = function() {
  return /** @type {!proto.toit.api.HardwareEvent.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.toit.api.HardwareEvent.Type} value */
proto.toit.api.HardwareEventsRequest.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint64 limit = 4;
 * @return {number}
 */
proto.toit.api.HardwareEventsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.toit.api.HardwareEventsRequest.prototype.setLimit = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool reverse = 5;
 * @return {boolean}
 */
proto.toit.api.HardwareEventsRequest.prototype.getReverse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.toit.api.HardwareEventsRequest.prototype.setReverse = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bytes id = 6;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.HardwareEventsRequest.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes id = 6;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.toit.api.HardwareEventsRequest.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.toit.api.HardwareEventsRequest.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.HardwareEventsRequest.prototype.setId = function(value) {
  jspb.Message.setOneofField(this, 6, proto.toit.api.HardwareEventsRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.toit.api.HardwareEventsRequest.prototype.clearId = function() {
  jspb.Message.setOneofField(this, 6, proto.toit.api.HardwareEventsRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.api.HardwareEventsRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp ts = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.toit.api.HardwareEventsRequest.prototype.getTs = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.toit.api.HardwareEventsRequest.prototype.setTs = function(value) {
  jspb.Message.setOneofWrapperField(this, 7, proto.toit.api.HardwareEventsRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.api.HardwareEventsRequest.prototype.clearTs = function() {
  this.setTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.api.HardwareEventsRequest.prototype.hasTs = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.HardwareEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.HardwareEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.HardwareEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.HardwareEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && proto.toit.api.HardwareEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.HardwareEventsResponse}
 */
proto.toit.api.HardwareEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.HardwareEventsResponse;
  return proto.toit.api.HardwareEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.HardwareEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.HardwareEventsResponse}
 */
proto.toit.api.HardwareEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.toit.api.HardwareEvent;
      reader.readMessage(value,proto.toit.api.HardwareEvent.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.HardwareEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.HardwareEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.HardwareEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.HardwareEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.toit.api.HardwareEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional HardwareEvent event = 1;
 * @return {?proto.toit.api.HardwareEvent}
 */
proto.toit.api.HardwareEventsResponse.prototype.getEvent = function() {
  return /** @type{?proto.toit.api.HardwareEvent} */ (
    jspb.Message.getWrapperField(this, proto.toit.api.HardwareEvent, 1));
};


/** @param {?proto.toit.api.HardwareEvent|undefined} value */
proto.toit.api.HardwareEventsResponse.prototype.setEvent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.api.HardwareEventsResponse.prototype.clearEvent = function() {
  this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.api.HardwareEventsResponse.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.ClaimHardwareIdentityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.ClaimHardwareIdentityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.ClaimHardwareIdentityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.ClaimHardwareIdentityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flashStationSecret: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.ClaimHardwareIdentityRequest}
 */
proto.toit.api.ClaimHardwareIdentityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.ClaimHardwareIdentityRequest;
  return proto.toit.api.ClaimHardwareIdentityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.ClaimHardwareIdentityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.ClaimHardwareIdentityRequest}
 */
proto.toit.api.ClaimHardwareIdentityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlashStationSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.ClaimHardwareIdentityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.ClaimHardwareIdentityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.ClaimHardwareIdentityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.ClaimHardwareIdentityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlashStationSecret();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string flash_station_secret = 1;
 * @return {string}
 */
proto.toit.api.ClaimHardwareIdentityRequest.prototype.getFlashStationSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.api.ClaimHardwareIdentityRequest.prototype.setFlashStationSecret = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.ClaimHardwareIdentityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.ClaimHardwareIdentityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.ClaimHardwareIdentityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.ClaimHardwareIdentityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareIdentity: (f = msg.getHardwareIdentity()) && toit_model_device_pb.HardwareIdentity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.ClaimHardwareIdentityResponse}
 */
proto.toit.api.ClaimHardwareIdentityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.ClaimHardwareIdentityResponse;
  return proto.toit.api.ClaimHardwareIdentityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.ClaimHardwareIdentityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.ClaimHardwareIdentityResponse}
 */
proto.toit.api.ClaimHardwareIdentityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new toit_model_device_pb.HardwareIdentity;
      reader.readMessage(value,toit_model_device_pb.HardwareIdentity.deserializeBinaryFromReader);
      msg.setHardwareIdentity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.ClaimHardwareIdentityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.ClaimHardwareIdentityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.ClaimHardwareIdentityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.ClaimHardwareIdentityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      toit_model_device_pb.HardwareIdentity.serializeBinaryToWriter
    );
  }
};


/**
 * optional toit.model.HardwareIdentity hardware_identity = 1;
 * @return {?proto.toit.model.HardwareIdentity}
 */
proto.toit.api.ClaimHardwareIdentityResponse.prototype.getHardwareIdentity = function() {
  return /** @type{?proto.toit.model.HardwareIdentity} */ (
    jspb.Message.getWrapperField(this, toit_model_device_pb.HardwareIdentity, 1));
};


/** @param {?proto.toit.model.HardwareIdentity|undefined} value */
proto.toit.api.ClaimHardwareIdentityResponse.prototype.setHardwareIdentity = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.api.ClaimHardwareIdentityResponse.prototype.clearHardwareIdentity = function() {
  this.setHardwareIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.api.ClaimHardwareIdentityResponse.prototype.hasHardwareIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.SetHardwareIdentityInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.SetHardwareIdentityInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.SetHardwareIdentityInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.SetHardwareIdentityInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flashStationSecret: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hardwareId: msg.getHardwareId_asB64(),
    info: (f = msg.getInfo()) && toit_model_device_pb.HardwareIdentityInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.SetHardwareIdentityInfoRequest}
 */
proto.toit.api.SetHardwareIdentityInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.SetHardwareIdentityInfoRequest;
  return proto.toit.api.SetHardwareIdentityInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.SetHardwareIdentityInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.SetHardwareIdentityInfoRequest}
 */
proto.toit.api.SetHardwareIdentityInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlashStationSecret(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHardwareId(value);
      break;
    case 3:
      var value = new toit_model_device_pb.HardwareIdentityInfo;
      reader.readMessage(value,toit_model_device_pb.HardwareIdentityInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.SetHardwareIdentityInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.SetHardwareIdentityInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.SetHardwareIdentityInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.SetHardwareIdentityInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlashStationSecret();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHardwareId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      toit_model_device_pb.HardwareIdentityInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string flash_station_secret = 1;
 * @return {string}
 */
proto.toit.api.SetHardwareIdentityInfoRequest.prototype.getFlashStationSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.toit.api.SetHardwareIdentityInfoRequest.prototype.setFlashStationSecret = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes hardware_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.toit.api.SetHardwareIdentityInfoRequest.prototype.getHardwareId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes hardware_id = 2;
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {string}
 */
proto.toit.api.SetHardwareIdentityInfoRequest.prototype.getHardwareId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHardwareId()));
};


/**
 * optional bytes hardware_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHardwareId()`
 * @return {!Uint8Array}
 */
proto.toit.api.SetHardwareIdentityInfoRequest.prototype.getHardwareId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHardwareId()));
};


/** @param {!(string|Uint8Array)} value */
proto.toit.api.SetHardwareIdentityInfoRequest.prototype.setHardwareId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional toit.model.HardwareIdentityInfo info = 3;
 * @return {?proto.toit.model.HardwareIdentityInfo}
 */
proto.toit.api.SetHardwareIdentityInfoRequest.prototype.getInfo = function() {
  return /** @type{?proto.toit.model.HardwareIdentityInfo} */ (
    jspb.Message.getWrapperField(this, toit_model_device_pb.HardwareIdentityInfo, 3));
};


/** @param {?proto.toit.model.HardwareIdentityInfo|undefined} value */
proto.toit.api.SetHardwareIdentityInfoRequest.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.toit.api.SetHardwareIdentityInfoRequest.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.toit.api.SetHardwareIdentityInfoRequest.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.toit.api.SetHardwareIdentityInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.toit.api.SetHardwareIdentityInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.toit.api.SetHardwareIdentityInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.SetHardwareIdentityInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.toit.api.SetHardwareIdentityInfoResponse}
 */
proto.toit.api.SetHardwareIdentityInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.toit.api.SetHardwareIdentityInfoResponse;
  return proto.toit.api.SetHardwareIdentityInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.toit.api.SetHardwareIdentityInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.toit.api.SetHardwareIdentityInfoResponse}
 */
proto.toit.api.SetHardwareIdentityInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.toit.api.SetHardwareIdentityInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.toit.api.SetHardwareIdentityInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.toit.api.SetHardwareIdentityInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.toit.api.SetHardwareIdentityInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.toit.api);
