import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CONSOLE_URL } from "./../App";
import ChangePasswordView from "./ChangePasswordView";
import ErrorView from "./ErrorView";
import InitiateResetPasswordView from "./InitiateResetPasswordView";
import SignInView from "./SignInView";
import SignUpView from "./SignUpView";

interface PageComponent {
  name: string;
  path: string;
  exact: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  main: React.ComponentType<any>;
}

const pages: PageComponent[] = [
  {
    name: "Initiate password reset",
    path: "/resetpassword/:email?",
    exact: true,
    main: InitiateResetPasswordView,
  },
  {
    name: "Change Password",
    path: "/changepassword/:token",
    exact: true,
    main: ChangePasswordView,
  },
  {
    name: "Sign up",
    path: "/signup",
    exact: true,
    main: SignUpView,
  },
  {
    name: "Sign in",
    path: "/login",
    exact: true,
    main: SignInView,
  },
  {
    name: "Error",
    path: "/error",
    exact: true,
    main: ErrorView,
  },
];

class MainView extends React.Component {
  componentDidMount(): void {
    const path = window.location.pathname;
    if (
      path !== "/error" &&
      path !== "/signup" &&
      path !== "/login" &&
      !path.startsWith("/changepassword") &&
      !path.startsWith("/resetpassword")
    ) {
      window.location.href = CONSOLE_URL + "/login";
    }
    analytics.page();
  }
  render(): JSX.Element {
    return (
      <Router>
        <Switch>
          {pages.map((page, index) => (
            <Route key={index} path={page.path} exact={page.exact} component={page.main} />
          ))}
        </Switch>
      </Router>
    );
  }
}

export default MainView;
