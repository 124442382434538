import {
  Box,
  Button,
  createStyles,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import queryString from "query-string";
import React from "react";
import { Helmet } from "react-helmet";
import { OAUTH_URL } from "../App";
import { ReactComponent as GithubLogo } from "../assets/images/github-logo.svg";
import { ReactComponent as GoogleLogo } from "../assets/images/google-logo.svg";
import { ReactComponent as Logo } from "../assets/images/toit-logo.svg";
import { black } from "../assets/theme/theme";
import CustomDivider from "./CustomDivider";

interface State {
  email: string;
  password: string;
  error?: string;
  success_uri?: string;
  client_id?: string;
  state?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
      width: "100vw",
      height: "100vh",
    },
    logo: {
      padding: theme.spacing(2),
    },
    signUpContent: {
      width: "50%",
      maxWidth: "450px",
      [theme.breakpoints.only("xs")]: {
        width: "70%",
        paddingTop: 0,
      },
    },
    content: {
      paddingTop: "18vh",
      [theme.breakpoints.only("xs")]: {
        paddingTop: "6vh",
      },
    },
    topButton: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    inputLabel: {
      color: black,
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    heading: {
      paddingBottom: theme.spacing(3),
    },
    link: {
      marginTop: theme.spacing(2),
    },
  });

type SignInProps = WithStyles<typeof styles>;

class SignInView extends React.Component<SignInProps, State> {
  handleTextChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        [name]: event.target.value,
      };
    });
  };

  componentDidMount() {
    const values = queryString.parse(document.location.search);
    const err = this.getLast(values.error);
    this.setState({
      success_uri: this.getLast(values.success_uri),
      state: this.getLast(values.state),
      client_id: this.getLast(values.client_id),
      error:
        err === "login_failed"
          ? "Username and password did not match"
          : err === "invalid_success_uri"
          ? "Malformed OAuth2 login request"
          : err === "internal_error"
          ? "Internal error, try again"
          : undefined,
    });
  }

  getLast = (v: string | string[] | null) => {
    if (v === null || v === undefined) {
      return undefined;
    }
    if (typeof v == "string") {
      return v;
    }
    return v[v.length - 1];
  };

  render() {
    const state: State = this.state || {};

    return (
      <Grid container className={this.props.classes.container}>
        <Helmet title="Sign in - Toit" />
        <Grid item xs={12}>
          <Grid xs={12} item>
            <Link href="https://toit.io">
              <Logo className={this.props.classes.logo} />
            </Link>
          </Grid>
          <Grid container item xs={12} justify="center" className={this.props.classes.content}>
            {!state.success_uri || !state.client_id || !state.state ? (
              <Typography variant="h2" align="center">
                Malformed OAuth2 login request
              </Typography>
            ) : (
              <form action={OAUTH_URL + "/login"} noValidate method="POST" className={this.props.classes.signUpContent}>
                <input type="hidden" value={state.success_uri} name="success_uri" />
                <input type="hidden" value={state.client_id} name="client_id" />
                <input type="hidden" value={state.state} name="state" />

                <Grid container item justify="center" className={this.props.classes.heading}>
                  <Typography variant="h1">Sign in to Toit</Typography>
                </Grid>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<GoogleLogo />}
                  className={this.props.classes.topButton}
                  href={OAUTH_URL + "/oauth/login/google?success_uri=" + encodeURIComponent(state.success_uri || "")}
                >
                  Sign in with Google
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<GithubLogo />}
                  href={OAUTH_URL + "/oauth/login/github?success_uri=" + encodeURIComponent(state.success_uri || "")}
                >
                  Sign in with GitHub
                </Button>
                <Box className={this.props.classes.divider}>
                  <CustomDivider title="or" />
                </Box>
                <InputLabel className={this.props.classes.inputLabel}>Sign in with email</InputLabel>
                {state.error !== null && <FormHelperText error={true}>{state.error}</FormHelperText>}
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={this.handleTextChange("email")}
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  onChange={this.handleTextChange("password")}
                  autoComplete="current-password"
                />
                <div className={this.props.classes.button}>
                  <Button type="submit" fullWidth variant="contained" color="primary">
                    Sign in
                  </Button>
                </div>
                <div className={this.props.classes.button}>
                  <Grid item container xs={12} justify="center" className={this.props.classes.link}>
                    <Link href="/signup">Not a user yet? Sign up</Link>
                  </Grid>
                  <Grid item container xs={12} justify="center" className={this.props.classes.link}>
                    <Link href={`/resetpassword/${state.email || ""}`}>Reset Password</Link>
                  </Grid>
                </div>
              </form>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SignInView);
