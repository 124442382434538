import {
  Avatar,
  Box,
  Button,
  Checkbox,
  createStyles,
  DialogContentText,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import { CONSOLE_URL } from "../App";
import { ReactComponent as CheckMark } from "../assets/images/checkmark.svg";
import { ReactComponent as DevBoard } from "../assets/images/dev-board.svg";
import { ReactComponent as GithubLogo } from "../assets/images/github-logo.svg";
import { ReactComponent as GoogleLogo } from "../assets/images/google-logo.svg";
import { ReactComponent as Logo } from "../assets/images/toit-logo.svg";
import { black, toitGreen } from "../assets/theme/theme";
import CustomDivider from "./CustomDivider";
import { SnackBar } from "./SnackBar";

interface State {
  error?: string;
  isSending: boolean;
  snackbarOpen: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    descriptionContainer: {
      background: toitGreen,
    },
    container: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
      width: "100vw",
      height: "100vh",
      overflowX: "scroll",
    },
    logo: {
      padding: theme.spacing(2),
    },
    signUpContent: {
      width: "50%",
      maxWidth: "450px",
      [theme.breakpoints.only("xs")]: {
        width: "70%",
      },
    },
    leftContent: {
      paddingTop: "18vh",
      [theme.breakpoints.only("xs")]: {
        paddingTop: "0",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "6vh",
      },
    },
    rightContent: {
      paddingTop: "26vh",
      [theme.breakpoints.down("md")]: {
        paddingTop: "10vh",
      },
    },
    topButton: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    inputLabel: {
      color: black,
    },
    signUpButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    checkmarkText: {
      marginLeft: theme.spacing(1),
    },
    perkList: {
      marginTop: theme.spacing(3),
    },
    avatar: {
      backgroundColor: "transparent",
    },
    mobileSpacer: {
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(10),
      },
    },
    heading: {
      paddingBottom: theme.spacing(3),
    },
    planContent: {
      paddingBottom: theme.spacing(6),
    },
    link: {
      textDecorationLine: "underline",
    },
    textSpacing: {
      paddingBottom: theme.spacing(2),
    },
    checkboxForm: { marginLeft: 0 },
    checkbox: { padding: 0, paddingRight: 9 },
  });

type SignUpFormProps = {
  handleSuccess: () => void;
};

/** The values controlled by this form. */
interface SignUpValues {
  email: string;
}

interface SignUpProps extends WithStyles<typeof styles> {
  history: History;
}

/** The values controlled by this form. */
interface SignUpValues {
  email: string;
  subscribed_to_newsletter: boolean;
}

class SignUpView extends React.Component<SignUpProps, State> {
  async onSubmit(values: SignUpValues) {
    this.setState({ isSending: true });
    let error = "";
    try {
      const body = JSON.stringify(values);
      const response = await fetch("https://console.toit.io/forms/create_organization", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
        credentials: "include",
      });
      if (!response.ok) {
        throw Error(await response.text());
      }
      this.setState({ snackbarOpen: true });
    } catch (e) {
      error = "Something went wrong. Please try again.";
      if (e instanceof Error) {
        error += ` Error: ${e.message}`;
      }
      this.setState({ error: error });
    } finally {
      if (error !== "") {
        analytics.track("Signup Form Failed", { values, error: error });
      } else {
        analytics.track("Signup Form Succeeded", values);
        analytics.track("SignUp", {}, { integrations: { All: false, Reddit: true } });
        setTimeout(() => {
          window.location.href = CONSOLE_URL + "/login";
        }, 3000);
      }
      this.setState({ isSending: false });
    }
  }

  public readonly state: State = {
    isSending: false,
    snackbarOpen: false,
    error: "",
  };

  initialValues: SignUpValues = {
    email: "",
    subscribed_to_newsletter: false,
  };

  validationSchema: yup.SchemaOf<SignUpValues> = yup.object({
    email: yup.string().email("Enter a valid email").required("Email is required"),
    subscribed_to_newsletter: yup.bool().defined(),
  });

  render() {
    return (
      <Grid container className={this.props.classes.container}>
        <Helmet title="Sign up - Toit" />
        <SnackBar
          open={this.state.snackbarOpen}
          type="success"
          onClose={() => {
            this.setState({ snackbarOpen: false });
          }}
        >
          User created, please check your email
        </SnackBar>
        {this.state.snackbarOpen ? (
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=3155756&conversionId=4095170&fmt=gif"
          />
        ) : null}
        <Grid item xs={12} md={8}>
          <Grid xs={12} item>
            <Link href="https://toit.io">
              <Logo className={this.props.classes.logo} />
            </Link>
          </Grid>
          <Grid container item xs={12} justify="center" className={this.props.classes.leftContent}>
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.onSubmit.bind(this)}
            >
              {(props) => (
                <form noValidate className={this.props.classes.signUpContent} onSubmit={props.handleSubmit}>
                  {this.state.error !== null && <FormHelperText error={true}>{this.state.error}</FormHelperText>}

                  <Grid container item justify="center" className={this.props.classes.heading}>
                    <Typography variant="h1">Sign up to Toit</Typography>
                  </Grid>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<GoogleLogo />}
                    className={this.props.classes.topButton}
                    href={CONSOLE_URL + "/login/google"}
                  >
                    Sign up with Google
                  </Button>
                  <Button variant="outlined" fullWidth startIcon={<GithubLogo />} href={CONSOLE_URL + "/login/github"}>
                    Sign up with GitHub
                  </Button>
                  <Box className={this.props.classes.divider}>
                    <CustomDivider title="or" />
                  </Box>
                  <InputLabel className={this.props.classes.inputLabel}>Sign up with your email</InputLabel>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    disabled={this.state.isSending}
                    value={props.values.email}
                    onChange={props.handleChange}
                    error={props.touched.email && Boolean(props.errors.email)}
                    helperText={props.touched.email && props.errors.email}
                  />
                  <DialogContentText>
                    <FormControlLabel
                      name="subscribed_to_newsletter"
                      value={props.values.subscribed_to_newsletter}
                      disabled={this.state.isSending}
                      onChange={props.handleChange}
                      control={<Checkbox color="primary" size="small" className={this.props.classes.checkbox} />}
                      label="I want to receive the Toit newsletter."
                      labelPlacement="end"
                      className={this.props.classes.checkboxForm}
                    />
                  </DialogContentText>
                  <div className={this.props.classes.signUpButton}>
                    <Button fullWidth type="submit" variant="contained" disabled={this.state.isSending} color="primary">
                      Sign up
                    </Button>
                  </div>
                  <Typography className={this.props.classes.textSpacing}>
                    By signing up, I agree to the Toit{" "}
                    <Link className={this.props.classes.link} href="https://toit.io/privacy-policy">
                      Privacy Policy
                    </Link>{" "}
                    and{" "}
                    <Link className={this.props.classes.link} href="https://toit.io/terms-of-service">
                      Terms of Service
                    </Link>
                    .
                  </Typography>
                  <Typography className={this.props.classes.mobileSpacer}>
                    Already have a user?{" "}
                    <a className={this.props.classes.link} href={CONSOLE_URL + "/login"}>
                      Sign in
                    </a>{" "}
                  </Typography>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={4} direction="column" className={this.props.classes.descriptionContainer}>
          <Grid item xs={12} justify="center" className={this.props.classes.rightContent}>
            <Grid container item xs={12} justify="center">
              <DevBoard />
            </Grid>
            <Grid container item xs={12} justify="center">
              <Typography variant="h2">Experience Toit today</Typography>
            </Grid>
            <Grid container direction="row" justify="center" className={this.props.classes.planContent}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={this.props.classes.avatar}>
                      <CheckMark />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>Best high-level language for IoT</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={this.props.classes.avatar}>
                      <CheckMark />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>Sandboxed containers on your microcontrollers</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={this.props.classes.avatar}>
                      <CheckMark />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>No credit card required</ListItemText>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SignUpView);
